/*
  All this css is only temporary and should be moved to other global files or to component folders
  ### or /// = todos
*/

// Components

// Temporary

.coming_soon {
  position: relative;
  color: var(--fg-lighter) !important;
  cursor: not-allowed;

  &::after {
    content: "\25F7";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    color: var(--fg-lighter);
  }
}

// Files

.files {
  @extend %border-radius-large;

  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin: 0 x(-2);
  padding: x(0.5);
  background-color: var(--bg-light);

  &:not(:last-child) {
    margin-bottom: x(2);
  }
}

.file {
  @extend %button;
  @extend %button-secondary;

  flex: 0 0 240px;
  margin: x(0.5) 0;
  padding: x(1.5);
  display: flex;
  align-items: center;
  border: 1px solid var(--bdr-light);
  text-align: left;
  min-height: var(--touch-target);

  .icon {
    margin-right: x(1);
  }

  .title {
    margin-bottom: 0;
  }
}

// Section columns hack
// Hack to show order status at the top of card in cards

.section__columns.orderstatusincase {
  position: absolute;
  top: x(2);
  right: x(3);
  margin: 0;

  > .section__column {
    flex: none;
    margin: 0;
  }
}

// Login form

.login_form__logo {
  text-align: center;
}

// Dropzone area (not column dropzones)

.dropzone__area {
  @extend %dropzone-area;

  &.is-loading {
    @extend %animation-loading;

    &::before {
      height: 2px;
    }
  }

}

// Avatar

.avatar {
  @extend %avatar-outline;
  @extend %unselectable;

  > img {
    border-radius: 100%;
    width: var(--icon);
    height: var(--icon);
  }

  &--16::before {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }

  &--24 > img {
    width: var(--icon-1-5);
    height: var(--icon-1-5);
  }

  &--30 > img {
    width: var(--icon-2);
    height: var(--icon-2);
  }
}

// Icon

span.icon {
  vertical-align: middle;
  display: inline-block;
  align-self: center;

  button & {
    margin-right: x(1);
  }

  button &--1 {
    margin-left: x(-0.5);
  }
}

svg.icon {
  @extend %animate-stroke;

  display: block;

  &--1 {
    stroke-width: var(--width-stroke);
  }

  &--1-5 {
    stroke-width: var(--width-stroke-1-5);
  }

  &--2 {
    stroke-width: var(--width-stroke-2);
  }

  &--2-5 {
    stroke-width: var(--width-stroke-2-5);
  }

  &--3 {
    stroke-width: var(--width-stroke-3);
  }
}

// Special mod for order icon to scale dots

svg.icon__group-order--color g > circle {
  stroke: var(--white);
}

// Infinity
// Easter

@keyframes infinity {
  0%,
  100% {
    transform: rotate(-360deg);
    stroke-dashoffset: 0;
    stroke: var(--purple);
  }

  25% {
    transform: rotate(-360deg);
    stroke-dashoffset: 100;
    stroke: var(--turqoise);
  }

  50% {
    transform: rotate(-180deg);
    stroke-dashoffset: 100;
    stroke: var(--green_mint);
  }

  75% {
    transform: rotate(-180deg);
    stroke-dashoffset: 0;
    stroke: var(--red);
  }
}

#app .item--no_content {
  svg {
    transition: transform 10s linear, box-shadow 5s linear;
    border-radius: 50%;
  }
}

#app .item--no_content:active {
  z-index: 1000;

  .tooltip__inner {
    opacity: 0 !important;
  }

  svg {
    transform: scale(10);
    box-shadow: 0 0 20px var(--grey);
  }

  svg.icon__infinity path {
    animation: infinity 4s linear infinite;
    transform-origin: 50% 50%;
    stroke: var(--purple);
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
  }
}

// Link

.link {
  display: inline-block;
  vertical-align: middle;

  &--inline {
    vertical-align: baseline;
  }

  > span {
    vertical-align: middle;
  }

  span.icon {
    margin-right: x(0.5);
  }

  &.is-external {
    position: relative;
    white-space: nowrap;

    &::after {
      @extend %animate-opacity;

      content: "";
      display: inline-block;
      width: var(--icon);
      height: var(--icon);
      margin: x(-0.5) 0 0 2px;
      background-image: var(--url-svg-external);
      opacity: 0.5;
    }

    &:hover::after,
    &:focus::after {
      opacity: 1;
    }
  }

  &.is-destructive {
    @extend %link-destructive;
  }

  &--nav {
    @extend %actionable;
    @extend %border-radius;

    padding: x(1.5) x(2);
    margin: x(-2) x(-2) 0;

    &:hover,
    &:focus {
      background-color: var(--bg-white);
    }

    .card &:hover,
    .card &:focus {
      background-color: var(--bg-nav-hover);
    }
  }

  .card &--nav {
    background: var(--bg-white);
  }
}

.icon_link {
  &.is-destructive:hover path,
  &.is-destructive:focus path {
    stroke: var(--red);
  }
}

// Card

.card {
  @extend %card;

  margin-bottom: x(2);
  position: relative;

  &.is-loading {
    @extend %animation-loading;
  }

  &--small {
    width: 420px;
  }

  &.login_form {
    margin-top: x(8);
  }

  &--note {
    display: flex;

    .content {
      flex: 1 1 auto;
      line-height: var(--line-height);
      white-space: pre-line;
    }

    .meta--timestamp {
      padding: 0 x(1);
      text-align: right;
      color: var(--fg-light);
    }

    .user {
      display: flex;
      align-items: center;
      margin-bottom: x(1);
    }

    .avatar {
      margin-right: x(1);

      span.icon {
        display: block;
      }
    }
  }

  &--modal {
    @extend %distance-huge;

    min-width: calc(var(--width-column) * 2);
    margin: x(2) 0;

    &.is-loading::before {
      height: 2px;
    }
  }

  &--loading {
    min-height: 320px;
    display: flex;
    align-items: center;
  }
}

.load_message {
  flex: 1 1 auto;
  text-align: center;
}

.card__title {
  display: flex;
  align-items: baseline;
  margin-bottom: x(4);

  .detail {
    color: var(--fg-light);
    font-size: var(--font-size-large);
    line-height: var(--line-height);
    flex: 1 0 auto;

    > a {
      @extend %focusable;

      color: var(--fg-light);

      .section__head:hover & {
        color: var(--fg-link);
      }

      .section__head &:hover,
      .section__head &:focus {
        color: var(--fg-link-hover);
      }

      .section__head &:active {
        color: var(--fg-link-pressed);
      }
    }
  }

  .description + .detail {
    margin-left: x(1.5);
  }

  /// Ugly fix, move toolbar to right instead?
  .section__content--contacts & {
    margin-bottom: x(1);
  }
}

.card__content {
  margin-top: x(4);

  .section__column {
    margin-bottom: 0;
  }
}

.card__foot {
  .card__content + & {
    margin-top: x(4);
  }
}

// Map legend

.legend {
  display: flex;
  flex-direction: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: x(0.5);

  span.icon {
    display: block;
  }

  dt:not(:last-child) {
    margin-right: x(0.5);
  }

  dd {
    @extend %font-size-smallest;

    &:not(:last-child) {
      margin-right: x(0.5);
    }
  }
}

.legend__definition {
  display: flex;
  align-items: center;
  flex-direction: column;

  &:not(:last-child) {
    margin-right: x(0.5);
  }
}

// Field

.field {
  position: relative;
  flex: 1 1 auto;

  input {
    margin-bottom: 0;
  }

  > .text,
  .multiselect__control,
  .react-datepicker-wrapper input,
  select {
    width: 100%;
  }

  /// needed?
  // .multiselect {
  //   min-width: auto;
  // }

  &:not(.no_label) {
    > .text,
    .multiselect__control,
    .react-datepicker-wrapper input,
    select {
      height: var(--height-field);
    }

    .field__unit {
      padding-top: x(1);
    }
  }

  &.no_label .field__unit {
    height: var(--touch-target);
  }

  &.has-unit {
    > .text {
      padding-right: x(6);
    }
  }

  label {
    @extend %font-size-smaller;
    @extend %border-radius;

    pointer-events: none;
    position: absolute;
    top: x(0.5);
    left: x(1);
    padding-bottom: 0;
    letter-spacing: 1px;
    color: var(--fg-lighter);
    z-index: 2;
    line-height: var(--line-height);
  }

  .field__unit {
    pointer-events: none;
    position: absolute;
    right: x(1);
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
    color: var(--fg-lighter);
    letter-spacing: 1px;
    z-index: 2;

    div:not(.btn-toolbar) > & {
      bottom: x(1.5);
    }
  }

  /// Really should refactor fields and make them universal
  &.no_label.has_unit input {
    margin-bottom: 0;
  }

  input:checked + .option__description & label {
    color: var(--label-green);
  }

  .disabledparent input:not(:checked) + .option__description & label {
    color: var(--label-red);
  }

  .app & label + .react-datepicker-wrapper .text,
  label + .text,
  label + .option .option__description,
  label + select {
    padding-top: x(1);
  }

  label + textarea {
    padding-top: calc(#{x(2)} + #{x(1.5)});
  }

  label + .btn-toolbar--actions .multiselect .multiselect__value-container,
  label + .multiselect .multiselect__value-container {
    margin-top: x(1);
    cursor: pointer;
  }

  // If inline tags are showed, increase top margin
  label
  + .multiselect
  .multiselect__value-container--is-multi.multiselect__value-container--has-value {
    margin-top: x(2);
  }

  &--wide {
    min-width: 360px;
  }

  #app &--textarea {
    flex: 1 0;

    // This adds a bg to inline labels for textareas so you can scroll
    table:not(.is-locked) & label:first-child,
    div:not(.is-locked) & label:first-child {
      background: var(--bg-white);
      position: absolute;
      left: 1px;
      right: 12px;
      top: 1px;
      padding: x(0.5) x(1);
    }
  }

  &--select,
  &--multiselect {
    min-width: 240px;
  }

  // Reset
  .btn-toolbar & input,
  .btn-toolbar & .multiselect {
    margin-bottom: 0;
  }

  .textarea {
    display: block; // Avoid bottom margin in some browsers
  }

  // Make inputs align when there are two for a date range
  &--date_range {
    @extend %distance-small;
    @extend %animate-distance;
    @extend %field;

    min-height: 0;
    padding: 0;
    height: var(--height-field);

    label {
      top: calc(calc(var(--x) * 0.5) - 1px);
    }

    #app & input {
      width: var(--width-field-date);

      // Reset
      border-color: transparent;
      box-shadow: none;
      background: none;

      &:focus {
        @extend %focus;

        position: static;
      }
    }

    .react-datepicker-wrapper {
      flex: 1 1 auto;
      margin-top: -1px; // to counteract border on .field

      &:first-of-type {
        border-right: 1px solid var(--bdr);
      }

      .react-datepicker-wrapper + & {
        border-left: 1px solid var(--bdr-light);
      }

      .react-datepicker__input-container {
        width: 100%;
      }
    }
  }

  // Set widths based on type

  &--time {
    width: var(--width-field-time);
  }

  &--date {
    width: var(--width-field-date);
  }

  &--large {
    width: var(--width-field-large);

    /// Temp, used in UK order music
    &--textarea {
      grid-column: span 2;
      width: 100%;

      textarea {
        min-height: 12em;
      }
    }
  }

  &--xlarge {
    width: var(--width-field-xlarge);
  }

  &--xxlarge {
    width: var(--width-field-xxlarge);
  }

  &--small {
    width: var(--width-field-small);
  }

  &--min_width {
    width: var(--width-field-min);

    > .text {
      width: 100%;
    }
  }

  &--max_width {
    width: 100%;
    flex: 1 1 100%;

    > .text {
      width: 100%;
    }
  }
}

.field__group {
  flex: 1 1 100%;
  flex-wrap: wrap;
}

// form-group

.form-group {
  @extend %clearfix;

  margin-bottom: 0; // reset

  &:not(.form-group__column) {
    margin-top: x(2);
  }

  div:not(.btn-toolbar) > &:not(.form-group__column):not(:last-child) {
    margin-bottom: x(2);
  }

  div:not(.btn-toolbar) > &:not(.form-group__column):first-child {
    margin-top: 0;
  }

  .section__columns &:last-child {
    margin-bottom: 0;
  }

  &--columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &--static span.icon {
    margin: -2px x(0.5) 0 0;
  }
}

.form-group__column {
  flex: 1 0 50%;
  width: 50%;
  box-sizing: border-box;
  padding-right: x(2);

  &:last-child {
    padding-right: 0;
  }

  &--min_width {
    flex: 0 1 auto;
    width: auto;
  }

  &--vertical {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .form-group--columns--fluid & {
    flex: 1 0 auto;
    width: auto;
  }

  button,
  select,
  .multiselect,
  input,
  option {
    margin-right: x(1);
  }

  > *:last-child,
  > .btn-toolbar > *:last-child {
    margin-right: 0;
  }
}

// Btn-toolbar (button toolbar)

.btn-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  flex-wrap: wrap;

  > .react-datepicker-wrapper input,
  > a,
  > .right > a,
  > button,
  > .right > button,
  > select,
  > input,
  .app & > .option,
  > .option--confirmation,
  > .help_text,
  > .multiselect,
  > span.icon__sub,
  > .field,
  > .form-group,
  > .textarea,
  .app & > .btn-toolbar,
  > .message,
  > .static,
  > .right > .static,
  > .tag,
  > .right > .tag {
    margin-right: x(2);
    flex: 0 1 auto;
    margin-bottom: x(1.5);

    &:last-child {
      margin-right: 0;
    }

    &.is-small {
      margin-right: x(1);
      margin-bottom: x(1);
    }
  }

  .app & > .option:not(.option--confirm_field):not(.option--no_option) {
    margin-top: 0;
  }

  .link--nav + & {
    margin-top: x(-2);

    &:last-child {
      margin-right: 0;
    }
  }

  &.nowrap {
    flex-wrap: nowrap;
    white-space: nowrap; ///
  }

  a {
    display: inline-block;
    flex: 0 1 auto;
    white-space: nowrap;

    > span.icon {
      margin-right: x(0.5);
    }
  }

  > span.icon__sub {
    margin-right: x(1);
    margin-left: x(1);
  }

  .right {
    flex: 1 1 auto;
    display: flex;
    flex-direction: row-reverse;

    > *:last-child {
      margin-right: 0;
    }
  }

  #app.app & .no_margin,
  .no_margin {
    margin: 0;
  }

  &--header,
  &--footer {
    margin-bottom: x(-1.5);
  }

  .assets & .button {
    margin-bottom: 0;
  }

  &--modal {
    background: var(--bg);
    margin: x(3) x(-3) x(-3);
    padding: x(2) x(3) x(0.5);
    border-bottom-right-radius: var(--border-radius-large);
    border-bottom-left-radius: var(--border-radius-large);
  }

  &:only-child {
    flex: 1 1 auto;
  }

  &--right {
    justify-content: flex-end;
  }

  &--nowrap {
    flex-wrap: nowrap;
  }

  #app &--max_width {
    flex: 1 0;
  }

  .section__head .inner & {
    margin: x(6) 0 x(3);
  }
}

// Button, Select

button:not(.button) {
  &:focus {
    outline: none;
  }
}

.button {
  @extend %button;
  @extend %button-secondary;

  &.is-primary {
    @extend %button-primary;

    min-width: 96px;
  }

  &.is-destructive:not(.is-tertiary) {
    @extend %button-destructive;
  }

  &:disabled,
  &.is-secondary:disabled {
    background-color: var(--bg-disabled);
    color: var(--fg-light);
  }

  &.is-tertiary {
    @extend %animate-colors;

    border: 1px solid var(--btn-tertiary-bdr);
    box-shadow: none;
    cursor: pointer;
    background-color: var(--btn-tertiary);
    color: var(--btn-tertiary-fg);
    display: inline-flex;
    align-items: center;

    .has-action:hover .btn-toolbar:not(:hover) &.is-primary-action,
    .has-action:focus .btn-toolbar:not(:hover) &.is-primary-action,
    &:hover,
    &:focus {
      @extend %distance-medium;

      background-color: var(--btn-tertiary-hover);
      color: var(--btn-tertiary-fg-hover);
      border-color: var(--btn-tertiary-bdr-hover);
    }

    &:active {
      color: var(--btn-tertiary-fg-pressed);
    }

    .has-action:focus .btn-toolbar:not(:hover) &.is-primary-action,
    &:focus {
      outline: none;

      &::before {
        display: none;
      }
    }

    &.is-destructive {
      @extend %link-destructive;
    }

    &:disabled,
    &.is-disabled {
      color: var(--btn-tertiary-fg-disabled);
    }

    &.no_label {
      flex-direction: column;

      span.icon {
        flex: 1 1;
        display: flex;
        align-items: center;
        margin: 0;
      }
    }
  }

  // Time out destructive action in modals
  .overlay--timeout &.is-destructive {
    @extend %animation-time-out;
  }

  &.is-small {
    @extend %button-small;
  }

  &.is-tiny {
    @extend %button-tiny;
  }

  span.icon {
    margin-left: x(-0.5);
    margin-top: -1px;
  }

  &--max_width {
    width: 100%;
  }

  &--no_label {
    text-align: center;
    padding: 0;

    span.icon {
      margin-right: 0;
      margin-left: 0;
    }

    &:hover,
    &:focus {
      svg.icon:not(.is-color) *[stroke] {
        stroke: var(--fg-link-hover);
      }

      svg.icon:not(.is-color) *[fill]:not([fill="none"]) {
        fill: var(--fg-link-hover);
      }
    }

    &:active {
      svg.icon:not(.is-color) *[stroke] {
        stroke: var(--fg-link-pressed);
      }

      svg.icon:not(.is-color) *[fill]:not([fill="none"]) {
        fill: var(--fg-link-pressed);
      }
    }
  }

  .btn-toolbar--actions & {
    vertical-align: middle;
  }

  .td--actions & {
    margin-bottom: 0;
  }

  // Until all buttons have been converted
  /// done now?
  &--flex {
    display: flex;
    align-items: center;
    flex-direction: column;

    .button__content {
      display: flex;
      align-items: center;
      flex: 1 1 auto;
    }

    .button__description {
      margin-top: -2px; // To optically align better
    }

    #app &:not(.button--no_label) span.icon:not(.icon__external) {
      margin: 0 x(0.5) 0 x(-0.5);
    }

    #app & span.icon__external {
      margin: x(-0.5) x(-1) 0 x(0.5);
    }
  }
}

.select--max_width,
.multiselect--max_width {
  width: 100%;

  .btn-toolbar & {
    flex: 1 0;
  }
}

td select:only-child {
  margin-bottom: 0;
}

// Element with direct actions (search)

.player {
  @extend %animate-colors;

  &.is-playing .btn-toolbar--player {
    background-color: var(--bg-nav-selected);
  }
}

// Btn toolbar search

.btn-toolbar.btn-toolbar--search {
  position: relative;

  .text {
    margin: 0;
  }
}

// Btn toolbar actions

.btn-toolbar.btn-toolbar--actions {
  @extend %border-radius;

  margin: 0 0 x(1.5);
  background: var(--fld-bg);
  color: var(--fld-fg);
  box-shadow: inset 0 0 0 1px var(--fld-bdr);
  justify-content: space-between;
  position: relative;
  margin-top: calc(var(--x) * 1);

  * {
    margin: 0;
  }

  .sub_product & {
    margin-right: x(2);
  }

  .btn-toolbar__field {
    flex: 1 1 auto;

    &--range {
      display: flex;

      .key {
        @extend %animate-colors;

        min-height: var(--icon-2);
        min-width: calc(var(--touch-target) + x(1));
        box-sizing: border-box;
        line-height: var(--icon-2);
        padding: 0 x(0.5);
        margin: x(0.5);

        &--start {
          text-align: right;
          padding-left: x(1);
        }

        &--end {
          text-align: left;
          padding-right: x(1);
        }

        .player:not(.is-playing) & {
          color: var(--fg-light);
        }
      }

      .slider {
        flex: 1 1 auto;
        display: flex;
        flex-direction: row;
        align-items: center;

        input[type="range"] {
          height: var(--touch-target);
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  .button {
    min-height: calc(var(--touch-target) - #{x(1)});
    min-width: calc(var(--touch-target) - #{x(1)});
    margin: x(0.5);
    flex: 0 0 auto;

    &:not(.button--no_label) {
      padding: 0 x(1);
    }
  }
}

// Tiny player

.item__meta.item__meta--recording .description {
  padding-right: 0;
}

.item__audio_player {
  display: flex;
  align-items: center;

  .button.is-tiny {
    @extend %button-tiny;

    display: flex;
    align-items: center;
    margin: 0 x(1) 0 0;

    span.icon {
      margin: 0 x(0.5) 0 x(-0.5);
    }

    time {
      @extend %font-size-small;

      line-height: var(--icon);
      min-width: var(--touch-target);
      text-align: center;
    }
  }

  .slider {
    flex: 1 1 auto;

    input[type="range"] {
      height: var(--icon);
      margin: 0;
    }
  }

  input[type="range"]::-webkit-slider-thumb {
    height: x(1);
    width: x(1);
    margin-top: calc(calc(#{x(-1)} / 2) + 1px);
  }
}

// Column

.column {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
  min-width: var(--width-column);
  max-width: 512px;
  flex: 1 0 var(--width-column);
  border-right: 1px solid var(--bdr);

  &:last-child {
    border-right: 0;
  }

  &:not(.is-target):hover::before {
    opacity: 1;
  }

  > div {
    @extend %animate-opacity;
  }

  .dropzone {
    background-color: rgba(var(--turqoise-rgb), 0.2);

    &:hover,
    &:focus {
      background-color: rgba(var(--green-rgb), 0.2);
    }
  }

  &--detail {
    z-index: 1;
    flex: 1 1 100%;
    max-width: none;
    overflow: hidden; // Avoid double scrollbars
  }

  &--sidebar {
    min-width: var(--width-column-sidebar);
    max-width: var(--width-column-sidebar);

    &--left .column__inner {
      border-right: 4px double var(--bdr);
    }

    &--right .column__inner {
      border-left: 4px double var(--bdr);
    }
  }
}

// Column inner
// Make sidebars fixed overlays

.column__inner {
  position: fixed;
  top: calc(var(--height-panel_head) + 1px);
  bottom: 0;
  z-index: 10;
  width: var(--width-column-sidebar);
  display: flex;
  flex-direction: column;
  background-color: var(--bg);

  .column--sidebar--right & {
    right: 0;
  }

  .column--sidebar--left & {
    left: 0;
  }
}

// Wide view (only-child)

.column--single:only-child {
  max-width: none;

  .drag__content__list {
    display: table;
  }

  .item {
    display: table-row;
    box-shadow: inset 0 -1px 0 0 var(--bdr-light);

    &:not(.is-active):not(:hover):not(:focus) {
      background-color: var(--bg-white);
    }

    &.is-active {
      background-color: var(--bg-nav-selected);
    }
  }

  .item > .item__meta,
  .item__footer {
    display: table-cell;
    padding: x(1.5);
    vertical-align: top;

    &:first-child {
      padding-left: x(2);
    }

    &:last-child {
      padding-right: x(2);
    }
  }

  .item > .item__meta {
    white-space: nowrap;
  }

  .item__footer {
    padding-bottom: x(1);
  }

  .item__footer .item__tags {
    float: left;
    max-width: 100%;
  }
}

// Column Head

.column__head {
  @extend %animate-distance;

  flex: 0 0 auto;
  flex-wrap: wrap;
  position: relative;
  z-index: 2;
  min-height: calc(var(--height-column_head) + 1px); // border
  padding: x(0.5) 0;
  box-sizing: border-box;
  border-bottom: 1px solid var(--bdr);
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--bg-light);

  .todo--add & {
    cursor: pointer;
  }

  > * {
    ///
    flex: 0 1 auto;
  }

  .column.is-loading & {
    @extend %animation-loading-bottom;
  }

  &.is-active,
  .column:hover &,
  .column--single & {
    background-color: var(--bg-white);
  }

  .column:hover & {
    @extend %distance-small;
  }

  &--tabs {
    padding: 0;
  }

  &--search form {
    width: 100%;
  }
}

// Tabs

.tabs {
  display: flex;
  flex: 1 1 100%;
}

.tab {
  @extend %nav-button;
  @extend %animate-colors;
  @extend %focusable;

  flex-direction: row;
  height: var(--height-column_head);
  color: var(--fg-light);
  border-right: 1px solid var(--bdr);
  background-color: var(--bg-nav);

  &:last-child {
    border-right: none;
  }

  &:not(.is-active):hover,
  &:not(.is-active):focus {
    background-color: var(--bg-nav-hover);
    color: var(--fg);
  }

  &:not(.is-active):active {
    background-color: var(--bg-nav-pressed);
    color: var(--fg-dark);
  }

  &.is-active {
    background: var(--bg-nav-selected);
    color: var(--fg);
    cursor: default;
  }

  &--tool {
    flex: 0;
    min-width: var(--height-column_head);

    span.icon {
      flex: 1 1 auto;
    }

    svg.icon {
      display: inline;
    }

    .icon path {
      @extend %animate-colors;

      stroke: var(--fg-lighter);
    }

    &:hover .icon path,
    &:focus .icon path {
      stroke: var(--fg-link);
    }

    &:active .icon path {
      stroke: var(--fg-link-pressed);
    }
  }

  &--tool_close {
    &:not(.is-active):hover,
    &:not(.is-active):focus {
      background-color: var(--icn-fill-destructive);

      #app & span.icon__close path {
        stroke: var(--white);
      }

      .shortcut {
        color: rgba(var(--white-rgb), 0.5);
      }
    }
  }

  .description {
    flex: 1 1 auto;

    .count {
      @extend %count;
    }
  }
}

// Shortcut

.shortcut {
  @extend %unselectable;
  @extend %font-size-smallest;
  @extend %animate-opacity;

  position: absolute;
  top: 2px;
  left: x(0.5);
  color: var(--fg-lighter);
  line-height: var(--line-height);
  min-width: x(1);
  text-align: center;
  opacity: 0;
  text-transform: uppercase;

  .navbar:hover &,
  .column:hover & {
    opacity: 1;
  }

  &--long {
    right: x(0.5);
  }
}

.shortcut_icon {
  @extend %border-radius;

  border: 1px solid var(--grey);
  display: inline-block;
  height: 14px;
  min-width: 14px;
  text-align: center;

  // The below line was giving warning
  span + & {
    margin-left: x(0.5);
  }
}

// Column Head Meta

.column__head__meta {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap; // ### fix display when wrapping

  > .meta {
    margin-right: x(1);
  }

  .meta--tags .tag {
    margin-top: calc(#{x(0.5)} / 2);
    margin-bottom: calc(#{x(0.5)} / 2);
  }

  .tag--status {
    @extend %animate-colors;

    border-color: var(--bdr);
    background: none;

    .column:hover &,
    .column:focus & {
      border-color: var(--bdr-dark);
      background: var(--bg-white);
    }
  }
}

.column__head > span.icon {
  @extend %unselectable;

  margin: 0 x(0.5) 0 x(1.5);
  flex: 0 0 var(--icon-1-5);
}

// Column Head Title

.column__head__title {
  @extend %font-size;

  margin-right: x(1);
  margin-bottom: 0;
  font-weight: 500;
}

// Column head drawer

.column__drawer {
  flex: 0 1 auto;
  border-bottom: 1px solid var(--bdr);
  background: var(--bg-white);

  .filter_option {
    margin-bottom: x(1);
  }

  &--filters {
    background-color: var(--bg-nav-selected);
  }
}

.column__drawer__content {
  padding: x(1) x(1) x(0.5);

  .align_right {
    float: right; // ###
  }
}

// Column Content

.column__content {
  position: relative;
  opacity: 1;
  flex: 1;
  overflow: hidden;

  .column--detail & {
    display: flex;
    flex-direction: column;
  }
}

// Column Content List
// Inner element needed to enable scrolling

.column__content__list {
  @extend %column-scroll-overlay;

  overflow: hidden;
}

// Column dropzone
// Replaces old wrapping dropzone

.column__dropzone {
  @extend %border-radius;

  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.5;

  .columns--have_drag & {
    display: block;
  }

  .column.is-target & {
    background-color: var(--bg-target);
  }

  .column.is-target:hover & {
    background-color: var(--bg-target-hover);
  }
}

// Draggable content list (child of above)

.drag__content__list {
  @extend %column-scroll-overlay;

  > div {
    overflow: hidden; // Fixes bug with dnd sideways scrolling, don't remove
  }
}

// Toolbar
// Universal toolbar
// ...but only used in column head now

.toolbar {
  flex: 0 0 auto;
  display: flex;
  align-items: stretch;
  align-self: stretch;

  .column__head & {
    min-height: var(--height-column_head);
    margin: x(-0.5) 0;
  }
}

.toolbar__item {
  display: flex;
  flex-direction: row;
  align-items: center;

  .column--detail & {
    margin-left: x(2);
  }

  &.has-content {
    @extend %has-content;

    position: relative;
  }

  &--button {
    @extend %animate-colors;

    > a {
      @extend %border-radius;
      @extend %focusable;

      padding: 0 x(0.5);
      display: flex;
      align-items: center;
      flex-direction: row;
      height: 100%;

      &:hover,
      &:focus {
        background-color: var(--bg-nav-hover);
      }

      &:active {
        background-color: var(--bg-nav-pressed);
      }
    }
  }

  &--close {
    @extend %animate-colors;

    padding: 0 x(1);
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: var(--icn-fill-destructive);

      #app & span.icon__close--small path {
        stroke: var(--white);
      }
    }
  }

  &--spacer {
    width: x(3);
  }

  .column__head &--button {
    @extend %nav-button;

    > a {
      border-radius: 0;
    }

    .column.is-target & {
      display: none;
    }
  }

  &--count {
    @extend %animate-colors;

    margin-right: x(1);
    color: var(--fg-lighter);

    .column:hover &,
    .column:focus & {
      color: var(--fg);
    }

    &:only-child {
      margin-right: x(2);
    }
  }

  &--delete a:hover,
  &--delete a:focus {
    background-color: var(--red-lighter);
  }

  &--delete a:active {
    background-color: var(--red-light);
  }

  select,
  input {
    margin-bottom: 0;
  }
}

.toolbar__item__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.toolbar__item span.icon {
  flex: 1 0 auto;
  margin: 2px auto;
  display: flex;
  align-items: center;
}

.toolbar__item:not(.is-active) span.icon *[stroke] {
  @extend %animate-colors;

  stroke: var(--fg-lighter);

  .column:hover &,
  .column:focus & {
    stroke: var(--fg);
  }
}

.toolbar__item__label {
  @extend %font-size-smaller;

  margin-bottom: x(0.5);
  color: var(--fg);
  flex: 0 0 auto;
  text-align: center;

  .toolbar__item:not(.show_label) & {
    display: none; // ### Hide until we have proper tooltips
  }
}

// Draggable
// Rules for draggable items and new item spacer

.column:not(.column--single) .item__spacer {
  padding: x(0.5) 0;
}

.column:not(.column--single)
.drag__content__list
div
> div:first-child
.item__spacer {
  padding-top: x(1);
}

.column:not(.column--single) .draggable.is-dragging .item {
  @extend %distance-large;
}

// Item
// Objects in lists

.item {
  background: var(--bg);
  clear: both; // just in case
  padding: x(1) x(2);
  position: relative;

  // Defaults
  &--button {
    @extend %focusable;

    &.is-active {
      cursor: default;
    }
  }

  .column &--button.is-new {
    @extend %has-content;

    .item__meta--title {
      color: var(--fg-new);
    }
  }

  .column &--button.is-live {
    @extend %is-live;

    .item__meta--title {
      color: var(--fg-live);
    }
  }

  // Card view
  .column:not(.column--single) &--button {
    @extend %card-tiny;
    @extend %animate-distance;

    margin: 0 x(1);
    padding: x(1) x(1);
    background-color: var(--bg-card);

    &:not(.is-active):hover,
    &:not(.is-active):focus {
      @extend %distance-medium;
      @extend %animation-drag-hint;

      background-color: var(--bg-card-hover);
    }

    // New
    &.is-new {
      background-color: var(--bg-card-new);

      &:hover:not(.is-active),
      &:focus:not(.is-active) {
        background-color: var(--bg-card-new-hover);
      }
    }

    // Live
    &.is-live {
      background-color: var(--bg-card-live);

      &:hover:not(.is-active),
      &:focus:not(.is-active) {
        background-color: var(--bg-card-live-hover);
      }
    }
  }

  // Single column view
  .column--single &--button {
    border-bottom: 1px solid var(--bdr);
    background-color: var(--bg-item);

    &.is-active {
      background-color: var(--bg-item-selected);
    }

    &:not(.is-active):hover,
    &:not(.is-active):focus {
      background-color: var(--bg-item-hover);
    }

    &:not(.is-active):active {
      background-color: var(--bg-item-pressed);
    }

    // New
    &.is-new {
      background-color: var(--bg-item-new);

      &.is-active {
        background-color: var(--bg-item-new-selected);
      }

      &:not(.is-active):hover,
      &:not(.is-active):focus {
        background-color: var(--bg-item-new-hover);
      }

      &:not(.is-active):active {
        background-color: var(--bg-item-new-pressed);
      }
    }

    // Live
    &.is-live {
      background-color: var(--bg-item-live);

      &.is-active {
        background-color: var(--bg-item-live-selected);
      }

      &:not(.is-active):hover,
      &:not(.is-active):focus {
        background-color: var(--bg-item-live-hover);
      }

      &:not(.is-active):active {
        background-color: var(--bg-item-live-pressed);
      }
    }
  }

  /// make ticket
  &.was-active {
    @extend %animation-active;
  }

  // Has moved
  &.has-moved {
    opacity: 0.5;
  }

  // Show object snippet inline
  .snippet {
    margin: 0 x(-1) x(1);
    padding: x(1);
    background: rgba(var(--black-rgb), 0.15);
    mix-blend-mode: multiply;
    border-radius: 0;
    box-shadow: inset 0 1px 2px rgba(var(--black-rgb), 0.15);
  }

  // No content
  &--no_content {
    text-align: center;
    min-height: var(--icon-3);
    padding-top: x(2);

    &.item .item__meta {
      display: block;
      text-align: center;
    }

    .icon__infinity path {
      stroke: var(--fg-lighter);
    }
  }

  // Load more
  &--load_more {
    @extend %animate-colors;

    text-align: center;
    padding-top: x(2);
    padding-bottom: x(2);

    .column:not(.column--single) & {
      @extend %animate-colors;

      &:hover,
      &:focus {
        background-color: var(--bg);
      }
    }

    // For the first 'show new' at the top
    .column:not(.column--single) .column__head + & {
      border-bottom: 1px solid var(--bdr);
      padding: x(2) calc(#{x(0.5)} + #{x(1.5)});
      margin: 0;
      position: relative;
      z-index: 1;
      border-radius: 0;

      &,
      &:hover,
      &:focus {
        box-shadow: none;
      }
    }

    .item__meta,
    .button {
      margin-bottom: 0;
    }

    &.item .item__meta {
      @extend %link;

      display: block;
      text-align: center;
    }

    &.item.is-active .item__meta,
    &.item:hover .item__meta,
    &.item:focus .item__meta {
      color: var(--fg-link-hover);
    }

    .icon__reload {
      float: left;
    }
  }

  // Message
  &--message .item__meta {
    @extend %font-size-small;
  }
}

// Item banner

.column__head--tabs {
  flex-wrap: wrap;
}

// Banner
.banner {
  padding: x(1) x(2);
  flex: 1 1 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 0;
  background-color: var(--bg-white);
  border-top: 1px solid var(--bdr);
  text-align: center;

  .column:not(.column--detail) & {
    padding-top: x(0.5);
    padding-bottom: x(0.5);
  }

  // For the first 'show new' at the top
  /// still used?
  .column:not(.column--single) .column__head + & {
    border-bottom: 1px solid var(--bdr);
    padding: x(1) x(2);
    margin: 0;
    position: relative;
    z-index: 1;
    border-radius: 0;

    &,
    &:hover,
    &:focus {
      box-shadow: none;
    }
  }

  > span.icon {
    flex: 0 0;
    margin-right: x(1);

    .column:not(.column--detail) & {
      display: none;
    }
  }

  .item__meta {
    display: block;
    color: var(--fg);
    display: flex;
    flex: 1 1 auto;
    margin-top: x(0.5);
    text-align: center;
    align-items: center;

    .column--detail & > span {
      text-align: left;
    }

    > span:not(:last-child) {
      margin-right: x(0.5);
    }
  }

  span.person {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin: 0 0 0 x(0.5);
  }

  .item__meta__icon,
  .avatar {
    margin-right: x(0.75);
  }

  .item__meta__icon span.icon {
    margin-right: 0;
  }

  .button {
    margin: x(-1) 0 x(-1) x(1.5);
  }

  &--warning {
    background-color: var(--bnr-warning);
    color: var(--fg);
  }

  // In column head
  .toolbar + & {
    margin-top: x(0.5);
    margin-bottom: x(-0.5);
  }
}

// Audio player
// ### temporary code

.audio_player {
  display: flex;
  height: 41px;
  margin-bottom: x(1.5);

  button.play {
    flex: 0 0;
    padding-left: x(1);
    padding-right: x(1);

    span.icon {
      margin-right: 0;
      margin-left: 0;
    }
  }

  // ###
  img:last-child {
    flex: 1 1 auto;
    height: auto;
    display: inline-block;
    float: right;
  }
}

// Item drawer

.item__drawer {
  @extend %animate-colors;

  margin: x(0.5) x(-1) x(-1);
  border-top: 1px solid var(--bdr-light);
  background: var(--bg-light);
  border-bottom-right-radius: var(--border-radius-large);
  border-bottom-left-radius: var(--border-radius-large);

  .column--single & {
    margin-right: x(-2);
    margin-left: x(-2);
  }

  .item.is-active &,
  .item:hover &,
  .item:focus & {
    background-color: var(--bg-white);
  }

  .item:not(.is-active):hover &,
  .item:not(.is-active):focus & {
    border-color: var(--bdr);
  }

  .column--single &,
  .column--single .item:hover &,
  .column--single .item:focus & {
    background-color: transparent;
  }

  .item__meta {
    @extend %animate-colors;

    padding: x(0.5) 0;
    margin: 0;

    &:not(.column--single) {
      padding-right: x(1);
      padding-left: x(1);
    }

    .column--single & {
      padding-right: x(2);
      padding-left: x(2);
    }

    &:not(:first-child) {
      border-top: 1px solid var(--bdr-light);
    }

    .column--single .item:not(.is-active):hover &:not(:first-child),
    .column--single .item:not(.is-active):focus &:not(:first-child) {
      border-color: var(--bdr);
    }

    .tag {
      margin-bottom: 0;
    }

    .description {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      span:first-child {
        margin-right: x(0.5);
      }
    }
  }

  .item__case-order-note {
    @extend %animate-colors;
    //To avoid bottom white space
    padding: x(0.005) 0;
    margin: calc(var(--x) * -0.5) 0 0;

    &:not(.column--single) {
      padding-right: x(1);
      padding-left: x(1);
    }

    .column--single & {
      padding-right: x(2);
      padding-left: x(2);
    }

    .column--single .item:not(.is-active):hover &:not(:first-child),
    .column--single .item:not(.is-active):focus &:not(:first-child) {
      border-color: var(--bdr);
    }
  }
}

// Item image

.item__image {
  float: left;
  margin-right: x(1);

  img {
    @extend %border-radius;
  }
}

// Item Meta

.item__meta {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: x(0.5);
  color: var(--fg-light);

  .item.is-active &,
  .item:hover &,
  .item:focus & {
    color: var(--fg);
  }

  .app span.icon,
  span.icon {
    margin-right: x(1);
  }

  .description {
    flex: 1 1 auto;
    display: inline-block;
    line-height: 18px;
    padding-right: x(1.5); // So meta + detail are never too close

    .count {
      font-weight: 600;
    }
  }

  &--order .description {
    padding-right: 0;
  }

  .detail {
    @extend %font-size-small;
    @extend %animate-colors;

    color: var(--fg-light);
    text-align: right;
    flex: 0 1 auto;
    line-height: var(--line-height);
    white-space: nowrap;
    position: relative; // So external icon positions correctly

    svg.icon__external path,
    svg.icon__external polyline {
      stroke: var(--fg-light);
    }

    .item:hover &,
    .item:focus &,
    .item.is-active & {
      color: var(--fg);
    }

    span.icon__external {
      @extend %animate-opacity;
      @extend %border-radius;

      position: absolute;
      top: 1px;
      left: calc(#{x(-2)} - 2px);
      opacity: 0;
      overflow: hidden;
      margin: 0;
    }
  }

  .item.is-active & a.detail,
  .item:hover & a.detail,
  .item:focus & a.detail {
    color: var(--fg-link);

    span.icon__external {
      opacity: 1;
      width: var(--icon);
    }

    svg.icon__external path,
    svg.icon__external polyline {
      stroke: var(--fg-link);
    }

    &:hover,
    &:focus {
      color: var(--fg-link-hover);

      svg.icon__external path,
      svg.icon__external polyline {
        stroke: var(--fg-link-hover);
      }
    }
  }

  .item:hover & a.detail:active {
    color: var(--fg-link-pressed);
  }

  &--title {
    @extend %font-size;

    .detail {
      font-weight: 500;
    }
  }

  &--title_detail {
    @extend %font-size-small;

    margin-bottom: 0;
  }
}

// Item meta todos

.item__meta--todos {
  > .description > .count {
    ///
    @extend %count;

    border: 1px solid var(--bdr);
    border-radius: calc(#{x(1)} + 1px);
    background-color: var(--bg-white);
    color: var(--fg);
    height: 18px;
    min-width: 18px;
    margin-top: -1px;
    margin-bottom: -1px;

    &--due {
      background-color: var(--bg-count-due);
      color: var(--fg-count-due);
      border-color: var(--bdr-count-due);
    }

    &--overdue {
      background-color: var(--bdr-error);
      border-color: var(--bdr-error);
      color: var(--fg-white);
    }

    &--overdue {
      background-color: var(--bg-count-overdue);
      color: var(--fg-count-overdue);
      border-color: var(--bdr-count-overdue);
    }
  }
}

// Item title

.item__title:not(.item__meta) {
  @extend %font-size;

  font-weight: bold;
  margin-bottom: x(0.5);
}

// Item content
/// This was for FAQ items or maybe long form Notes,
/// but alas is not used

.item__content {
  @extend %font-size-small;

  line-height: var(--line-height);

  .item:not(.is-active) & {
    max-height: var(--touch-target);
    overflow: hidden;
    position: relative;
  }
}

.item:not(.is-active) .item__content::after {
  @extend %animate-opacity;
  @include gradient(transparent, var(--bg-light));

  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: var(--icon-1-5);
  z-index: 2;
}

.item:not(.is-active):hover .item__content,
.item:not(.is-active):focus .item__content {
  max-height: none;
  overflow: visible;

  &::after {
    opacity: 0;
  }
}

.item:not(.is-active):hover .item__content::after,
.item:not(.is-active):focus .item__content::after {
  @include gradient(transparent, var(--bg));
}

// Item footer

.item__footer {
  display: flex;
  flex-direction: row;
  margin: x(1) 0 -2px;
}

// Item toolbar

.item__toolbar {
  background: var(--bg);
  margin: x(1) x(-1) x(-1);
  padding: x(1) x(1.5);
  border-bottom-right-radius: var(--border-radius-large);
  border-bottom-left-radius: var(--border-radius-large);

  .btn-toolbar > a,
  .btn-toolbar > button,
  .btn-toolbar > select {
    margin-bottom: 0;
  }
}

// Item Tags

.item__tags {
  @extend %clearfix;

  flex: 1 1 auto;
  max-width: 100%;
}

// Tags

.tags {
  display: inline;
  margin-right: x(1);

  &:last-child {
    margin-right: 0;
  }

  &--color_picker {
    position: relative;

    svg.icon__drop_down {
      @extend %animate-opacity;

      opacity: 0;
    }

    .tag:hover svg.icon__drop_down,
    .tag:focus svg.icon__drop_down {
      opacity: 1;
    }
  }
}

// Tags group title

.tags__title {
  margin-right: x(0.5);
}

// Tag

.tag {
  @extend %border-radius;

  background: var(--bg-white);
  color: var(--fg);
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  padding: x(0.5) calc(#{x(0.5)} + 1px);
  margin-right: x(0.5);
  vertical-align: middle;
  border: 1px solid var(--bdr);
  line-height: var(--line-height);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;

  .tags & {
    margin-bottom: x(0.5);

    &:last-child {
      margin-right: 0;
    }
  }

  .section__meta & {
    margin-bottom: 0;
  }

  &.is-new {
    font-weight: bold;
  }

  > span.icon:not(.icon__external):not(.action):not(.icon__drop_down):not(.icon__check) {
    margin: -1px 0 -1px -1px;
    float: left;

    &:not(:last-child) {
      margin-right: x(0.5);
    }
  }

  &--small > span.icon:not(.icon__external) {
    margin: -1px 0 -1px -1px;
    float: left;

    &:not(:last-child) {
      margin-right: 2px;
    }
  }

  &--large > span.icon:not(.icon__external) {
    margin: -2px 0 -2px -2px;
    float: left;

    &:not(:last-child) {
      margin-right: x(0.75);
    }
  }

  > .icon__external,
  > span.action {
    margin: -1px -1px -1px 2px;
    float: right;
  }

  span.icon__locked svg * {
    stroke: var(--fg-light);
  }

  span.icon__close {
    margin: -1px -1px -1px x(0.5);
    float: right;
  }

  &--small,
  .tags--small & {
    @extend %font-size-small;

    padding: 2px 3px;
    height: var(--height-tag);
    line-height: 14px;
  }

  &--large,
  .tags--large & {
    padding: x(0.5) x(1);
  }

  .tags--max_width > & {
    width: 100%;
    white-space: normal;
    overflow: visible;
  }

  .tags--color_picker > &.tag--label::before,
  .tags--large > &.tag--label::before,
  .tags__color_picker > &.tag--label::before {
    width: x(2);
    height: x(2);
    margin: x(0.5) x(1) x(0.5) 0;
  }

  &--button {
    @extend %animate-distance;

    cursor: pointer;

    &:hover,
    &:focus,
    .notification_item:hover &,
    .notification_item:focus & {
      @extend %distance-medium;
    }

    &:focus {
      @extend %focus;
    }

    .tags--color_picker & {
      margin: 0;
    }
  }

  &--person {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    .tag--person + & {
      @extend %border-radius;
    }

    .item__meta__icon {
      float: left;
      margin: -1px calc(#{x(0.5)} + 1px) -1px -2px;

      &::before {
        border-color: transparent;
      }
    }

    .app & .description {
      margin: 0;
    }
  }

  .tags--owners:first-child &--owner {
    margin-left: -2px;

    span.icon:not(:only-child),
    span.avatar:not(:only-child) {
      margin-right: x(1);
    }

    svg.icon {
      margin-right: 0;
    }

    &.has-no_owner {
      margin-right: -2px;
    }
  }

  &--owner {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    span.icon,
    span.avatar {
      float: left;
      margin: -1px calc(#{x(0.5)} + 1px) -1px -2px;
    }

    #app &.has-no_owner {
      border-radius: 10px;
      padding: 1px;
      border: 1px dashed var(--bdr);

      span.icon,
      span.avatar {
        margin: 0;
      }
    }
  }

  .tags--owners:first-child &--owner {
    margin-left: -2px;

    span.item__meta__icon:not(:only-child) {
      margin-right: x(1);
    }

    svg.item__meta__icon {
      margin-right: 0;
    }

    &.has-no_owner {
      margin-right: -2px;
    }
  }

  &--todo {
    background: var(--bg-count);
    color: var(--fg-count);
    border: 1px solid var(--bdr-count);

    &--due {
      background-color: var(--bg-count-due);
      color: var(--fg-count-due);
      border-color: var(--bdr-count-due);

      .count {
        background-color: var(--bg-count-overdue);
      }
    }

    &--overdue {
      background-color: var(--bg-count-overdue);
      color: var(--fg-count-overdue);
      border-color: var(--bdr-count-overdue);

      .count {
        background-color: var(--bg-count-overdue);
      }

      svg path,
      svg polyline {
        stroke: var(--white);
      }
    }

    &--done {
      border-color: var(--bdr-success);
      background-color: var(--bg-success);
    }

    .count {
      background-color: var(--bg-dark);
      color: var(--fg-white);
      margin: -2px x(0.5) -2px -3px;
      padding: 2px 3px;
    }
  }

  &--linked {
    line-height: calc(var(--icon) - 4px);
    background-color: var(--bg-white);
    color: var(--fg);
    border: 1px solid var(--bdr-dark);
  }

  &--case {
    border-color: var(--bdr-accent);
    color: var(--accent-dark);

    svg * {
      stroke: var(--accent-dark);
    }
  }

  &--order {
    border-color: var(--orange);
    color: var(--orange-dark);

    .app & svg.icon:not(.is-static) path {
      stroke: var(--icn-stroke-orange);
    }

    .app & svg.icon:not(.is-static) circle {
      fill: var(--icn-fill-orange);
    }
  }

  &--call {
    border-color: var(--pink);
    color: var(--pink-dark);

    .app & svg.icon:not(.is-static) * {
      stroke: var(--icn-stroke-turqoise);
    }
  }

  &--location {
    border-color: var(--green_mint);
    color: var(--green_mint-dark);

    .app & svg.icon:not(.is-static) *[stroke="#373D3E"] {
      stroke: var(--icn-stroke-green);
    }
  }

  &--label {
    @include label(var(--lbl-grey_light));

    border: 1px solid var(--bdr);

    &::before {
      content: "";
      display: block;
      float: left;
      width: x(1);
      height: x(1);
      margin: x(0.5);
      background: var(--fg);
      border-radius: 50%;
      flex: 0 0 auto;
    }
  }

  // Label action

  .tags--large & .action {
    float: right;
    height: var(--icon-1-5);
    width: var(--icon-1-5);
    margin: 0 x(-0.5) 0 x(1);

    .icon {
      margin: 0;
    }
  }

  // Remove button
  .app .tags--large & .remove {
    @extend %animate-opacity;

    path {
      @extend %animate-stroke;

      stroke: var(--bg-white);
    }
  }

  .tags--large:hover & .remove path,
  .tags--large:focus & .remove path {
    stroke: var(--fg-lighterer);
  }

  &--small:hover .action.remove path,
  .tags--small &:hover .action.remove path,
  .tags--large & .remove:hover span path,
  .tags--large & .remove:focus span path {
    stroke: var(--red);
  }

  // Removed tag
  &--removed {
    text-decoration: line-through;
    opacity: 0.8;
  }

  // Locked tag
  &--locked {
    background-color: var(--bg-disabled);

    .remove {
      display: none;
    }
  }

  // Description
  .description {
    flex: 1 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Tag colors

.app .tag {
  &--grey_light {
    @include label(var(--lbl-grey_light));
  }

  &--grey {
    @include label(var(--lbl-grey));
  }

  &--red {
    @include label(var(--lbl-red));
  }

  &--orange {
    @include label(var(--lbl-orange));
  }

  &--yellow {
    @include label(var(--lbl-yellow));
  }

  &--green {
    @include label(var(--lbl-green));
  }

  &--green_mint {
    @include label(var(--lbl-green_mint));
  }

  &--turqoise {
    @include label(var(--lbl-turqoise));
  }

  &--blue {
    @include label(var(--lbl-blue));
  }

  &--purple {
    @include label(var(--lbl-purple));
  }

  &--pink {
    @include label(var(--lbl-pink));
  }

  &--pink_hot {
    @include label(var(--lbl-pink_hot));
  }
}

// Todo tags statuses

.tag--todo {
  background: var(--bg-count);
  color: var(--fg-count);
  border: 1px solid var(--bdr-count);

  &--due {
    background-color: var(--bg-count-due);
    color: var(--fg-count-due);
    border-color: var(--bdr-count-due);
  }

  &--overdue {
    background-color: var(--bg-count-overdue);
    color: var(--fg-count-overdue);
    border-color: var(--bdr-count-overdue);
  }
}

// Tag icon
// Show icons inline

#app .description + span.tag__icon {
  margin-left: x(0.5);
}

// Tags for multiuser editing

.tag--active {
  background-color: var(--tag-active);
  border-color: var(--tag-active-bdr);
}

.tag--warned {
  background-color: var(--tag-warned);
  border-color: var(--tag-warned-bdr);
}

// Section wrapper
// So we can do the temporary Notes sidebar

.section__wrapper {
  display: flex;
  flex-direction: row;
  height: calc(100% - 65px);
}

// Section

.section {
  background: var(--bg-white);

  .column:not(.column--detail) &--scroll &--scroll_probate {
    @extend %column-scroll-overlay;
  }

  .column--detail &--scroll {
    @extend %column-scroll;
    @extend %chrome-scroll-fix;

    flex: 1 1;
  }

  .column--detail &--scroll_probate {
    @extend %column-scroll;
    //Avoided 'chrome scroll fix'
    flex: 1 1;
  }

  .column--detail &--sidebar {
    flex: 0 0 var(--width-column-sidebar);
    background: var(--bg-light);
    display: flex;
    flex-direction: column-reverse;
  }

  &--inline {
    background-color: transparent;
    margin-bottom: x(4);
  }
}

// Add note

.add_note {
  padding: x(1);
  background: var(--bg-white);
  position: relative;
  z-index: 1;

  // Detail view
  .section--detail & {
    margin-top: x(-2);
  }

  // In sidebar
  .section--sidebar & {
    border-bottom: 1px solid var(--bdr);
  }

  .notes:hover + & {
    @extend %distance-small;
  }

  .btn-toolbar--footer {
    margin-bottom: x(-0.5);
  }
}

.notes {
  flex: 1 1;
  position: relative;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto; //to avoid double scrollBar use: hidden
  padding-bottom: x(2);
  background-color: var(--grey-lighterer);

  .item--no_content {
    background: none;
  }

  &.is-loading {
    @extend %animation-loading;
  }

  .message {
    padding-bottom: 0;
  }
}

.note {
  @extend %card-small;

  display: flex;
  flex-direction: column;
  margin: x(1);

  .head {
    margin-bottom: x(1.5);

    .avatar {
      margin-right: x(1);

      span.icon {
        display: block;
      }
    }

    .user {
      display: flex;
      align-items: center;
    }
  }

  .content {
    flex: 1 1 auto;
    line-height: var(--line-height);
    margin-bottom: x(1.5);
    white-space: pre-line;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  .foot {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: x(-0.5);

    .timestamp {
      @extend %font-size-small;

      flex: 1 1;
      color: var(--fg-light);
    }
  }
}

// Section toolbar

.section__toolbar:not(.section__toolbar--fixed) {
  margin-top: x(6);
}

.section__head .section__toolbar {
  display: flex;
}

.section__toolbar--filters {
  padding: x(1.5) 0 0;
  position: sticky;
  position: -webkit-sticky; // Needed to avoid SASS inserting incorrectly
  top: 0;
  z-index: 1;
  background-color: var(--bg-white);

  .section__head + & {
    border-bottom: 1px solid var(--bdr-light);
  }

  .btn-toolbar {
    align-items: stretch;
  }

  .btn-toolbar > *:not(.is-fixed_width) {
    flex: 1 1 auto;
  }
}

// Section head

.section__head {
  padding: x(2) 0 0;

  .section--detail > & {
    padding-bottom: x(2);
    border-bottom: 1px solid var(--bdr-light);
  }

  .drawer & {
    margin-top: x(-4);
  }

  .drawer .card & {
    margin-top: 0;
    padding: 0;
  }

  .inner {
    position: relative;
  }

  &.has-toolbar .inner {
    display: flex;

    .section__title {
      flex: 1 1 auto;
    }

    .btn-toolbar {
      margin: x(-2) 0;
    }

    select {
      margin-bottom: 0;
    }
  }
}

// Section title

.section__title {
  padding: x(6) 0 x(3);
  margin: 0;
  display: flex;
  align-items: baseline;
  clear: both;

  .tag--large {
    font-size: var(--font-size);

    span.icon {
      margin: 0 2px 0 -1px;
    }
  }

  .section__toolbar & {
    flex: 1 1 auto;
  }

  &:not(:first-child) {
    margin-top: x(4);
  }

  .form-group &:first-child {
    padding-top: 0;
  }

  .section--detail & {
    padding: 0;
  }

  > span.icon {
    margin-right: x(2);
  }

  .description {
    margin-right: x(1.5);

    &.is-placeholder {
      color: var(--fg-lighter);
    }
  }

  .detail {
    color: var(--fg-light);
    font-size: var(--font-size-large);
    line-height: var(--line-height);
    flex: 0 1 auto;

    span.icon__external {
      @extend %animate-opacity;

      opacity: 0.5;
      margin: x(-0.5) 0 0 x(0.5);
    }

    svg.icon__external path,
    svg.icon__external polyline {
      @extend %animate-stroke;

      stroke: var(--fg-light);
    }
  }

  .section__head:hover & a.detail {
    color: var(--fg-link);

    span.icon__external {
      opacity: 1;
    }

    svg.icon__external path,
    svg.icon__external polyline {
      @extend %animate-stroke;

      stroke: var(--fg-link);
    }

    &:hover,
    &:focus {
      color: var(--fg-link-hover);

      svg.icon__external path,
      svg.icon__external polyline {
        stroke: var(--fg-link-hover);
      }
    }
  }
}

h3.section__title {
  padding-top: 0;

  &:not(:first-child) {
    margin-top: x(4);
  }
}

h5.section__title {
  padding: 0 0 x(1.5);
  font-weight: 500;
}

.section__subtitle {
  color: var(--fg-light);

  &.has-icon {
    margin-left: x(5);
  }
}

// Section info
// info text below a section__title

.section__info {
  margin-bottom: x(5);

  > span.icon:first-child {
    margin-right: x(.5);
  }
}

// Section tags

.section__tags {
  margin: x(0.5) 0 0 x(4);
}

// Section meta

.section__meta {
  margin: 0;
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: x(0.5);
  }

  .section__title + & > span.icon {
    margin-left: calc(x(2) + var(--icon-1-5));
    margin: 0 18px 0 2px;
  }
}

// Section nav

.section__nav {
  margin-left: x(-1.5);
  position: relative;
  z-index: 1;
  clear: both;
}

.section__nav__item {
  @extend %unselectable;
  @extend %font-size-large;

  padding: x(3) x(1.5);
  text-align: center;
  min-width: 72px;
  display: inline-block;
  position: relative;
  background: var(--bg-white);
  white-space: nowrap;

  &.is-active {
    color: var(--fg);

    &:hover,
    &:focus {
      text-decoration: none;
      cursor: default;
    }
  }

  &::before {
    content: "";
    height: 4px;
    position: absolute;
    z-index: -1;
    left: 50%;
    right: 50%;
    bottom: -2px;
    background: var(--accent);
    border-radius: 2px;
    opacity: 0;
    transition: opacity 100ms ease, left 200ms ease-out, right 200ms ease-out;
  }

  &.is-active::before,
  &:hover::before,
  &:focus::before {
    opacity: 1;
    left: x(1.5);
    right: x(1.5);
  }

  &.is-active::before {
    background: var(--accent-dark);
  }
}

// Section content

.section__content {
  background: var(--bg);
  clear: both;

  &.is-loading {
    @extend %animation-loading;

    &::before {
      height: 2px;
    }
  }

  &:not(.drawer) {
    padding: x(6) 0;

    .card & {
      padding: 0;
    }
  }

  .section__head + &:not(.drawer) {
    margin-top: x(2);
  }

  .section--detail & {
    background: var(--bg-white);

    &:not(.drawer) {
      padding-top: x(2);
    }
  }

  &--fatal_error {
    text-align: center;

    .illustration {
      margin: 0 auto;
    }
  }

  &--publicProfile {
    height: calc(100% - 65px);
  }
}

// Section columns

.section__columns {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  margin: 0 x(-4);
}

.section__column {
  flex: 1 1;
  box-sizing: border-box;
  max-width: 620px;
  margin: 0 x(4) x(6);

  &--small {
    flex: 0.35 1;
  }

  .section__head & {
    margin-bottom: x(2);
  }
}

// Section column title

.section__column__title {
  &:not(:first-child) {
    margin-top: x(4);
  }

  .count {
    @extend %count-large;
  }
}

h5.section__column__title {
  margin-bottom: x(0.5);
}

// Section foot

.section__foot {
  flex: 0 1;
  background: var(--bg-white);
  border-top: 1px solid var(--bdr);
  padding-top: x(1.5);
  z-index: 1;

  &--static {
    position: sticky;
    bottom: 0;
  }
}

// Settings

.current_logo {
  margin-bottom: x(2);
}

.current_logo__outline {
  border: 1px solid var(--bdr-dark);
  padding: x(0.5);
  display: inline-block;
}

// Account

.current_avatar {
  @extend %avatar-outline;

  margin-bottom: x(2);

  // To roughly match scaling of rounded corners
  // of the WordPress avatars
  &--public {
    img {
      border-radius: 4px;
    }

    &::before {
      border-radius: 6px;
    }
  }
}

.text.text--initials {
  @extend %font-size-large;

  width: 64px;
  text-transform: uppercase;
  text-align: center;
}

// Location

.map {
  margin: x(-3) x(-4) x(6);
  height: 360px;
  position: relative;

  .column__section__content & {
    margin: 0;
    height: 280px;
    position: relative;
  }
}

// Matrix
// Select from a grid of options (used in Locations)

.matrix {
  width: 100%;

  tbody tr {
    @extend %animate-colors;

    cursor: pointer;
  }

  /// This is not used, the idea was to cycle options
  // on row click
  tbody tr:hover,
  tbody tr:focus {
    td {
      background-color: var(--turqoise-lightest);

      &:nth-child(2) label {
        color: var(--fg-link);
      }
    }
  }

  tbody:not(:hover) tr:nth-child(even) {
    background-color: var(--bg-light);
  }
}

// Asset
// Uploaded picture, document, or other resource

.assets {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 x(-2);
}

.asset {
  @extend %card-large;
  @extend %distance-medium;

  margin: 0 x(2) x(3);

  .assets & {
    flex: 1 1 auto;
    min-width: 320px;
    margin-right: x(2);
  }
}

.asset__preview {
  padding: 1px 0;
  margin: x(-4) x(-4) x(3);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  img {
    margin: x(2) auto x(0.5);
    display: block;
    border: 1px solid var(--bdr);
    padding: 2px;
    box-sizing: content-box;
  }
}

// Message

.message {
  @extend %distance-small;

  background: var(--bg-white);
  color: var(--fg-light);
  padding: x(1);
  box-sizing: border-box;
  pointer-events: all;
  margin-bottom: x(1);

  .notification > & {
    @extend %card-small;
    @extend %animate-distance;
    @extend %distance-large;

    padding-top: x(2);
    max-width: var(--width-column);

    &--button:hover,
    &--button:focus {
      @extend %distance-larger;

      background-color: var(--bg);
      cursor: pointer;
    }
  }

  .notification.is-new > & {
    background-color: var(--turqoise-lightest);

    &--button:hover,
    &--button:focus {
      background-color: var(--bg-nav-hover);
    }
  }

  > span {
    vertical-align: middle;
    display: inline-block;
  }

  > span.icon + span {
    margin-top: -2px; // To optically align better
  }

  .title,
  .description {
    margin-bottom: x(2);
  }

  .title {
    font-weight: 600;
  }

  &.is-success,
  &.is-warning,
  &.is-error {
    @extend %border-radius;

    line-height: var(--line-height);
  }

  &.is-success {
    background-color: var(--bg-success);
    color: var(--fg-white);
  }

  &.is-warning,
  &.is-error {
    background-image: var(--url-svg-warning--color);
    background-size: var(--icon-1-5) var(--icon-1-5);
    background-position: x(1) x(1);
    background-repeat: no-repeat;
    padding-left: calc(var(--icon-1-5) + #{x(2)});
  }

  &.is-error {
    background-image: var(--url-svg-error--color);
  }

  &--empty {
    height: calc(100% - #{x(2)});
    background-color: transparent;
    display: flex;
    align-items: center;
    flex-direction: row;
    text-align: center;
    box-shadow: none;
    flex: 1 1 auto;

    p {
      flex: 1;
    }

    span.icon {
      margin-bottom: x(2);

      .app &--illustration {
        margin-right: 0;
      }
    }

    svg.icon--illustration:not(.is-color) *[stroke] {
      stroke: var(--fg-lighterer);
    }

    svg.icon--illustration:not(.is-color) *[fill]:not([fill="none"]) {
      fill: var(--fg-lighterer);
    }

    .title {
      margin-bottom: x(0.5);
    }

    .description {
      margin-bottom: x(1);
      color: var(--fg-lighter);
    }

    // Easter
    .notifications & {
      .stretching {
        display: none;
        pointer-events: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        max-height: 100%;
        max-width: 100%;
        z-index: 1000;
      }

      .icon__zen:hover svg.icon__zen circle {
        fill: var(--yellow);
      }

      .icon__zen:hover + .stretching {
        display: block;
      }
    }
  }

  .section__foot & {
    box-shadow: none;
  }
}

.message__content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  .notification--toast & {
    align-items: center;
  }
}

.message span.icon {
  margin-right: x(1);
}

.message__close {
  float: right;
  margin: x(-1) x(-1) x(0.5) x(0.5);
}

// Drawer

.drawer {
  padding: x(4);

  &:not(:last-child) {
    margin-bottom: x(3);
  }

  &:not(.drawer--inline) {
    background: var(--bg);
  }

  &--embedded {
    @extend %border-radius-large;

    padding: x(1);

    &:not(:first-child) {
      margin-top: x(1);
    }
  }
}

// Snippet

.snippet {
  @extend %border-radius;

  background-color: var(--bg-light);
  padding: x(1) x(1.5) x(1.5);

  &:not(:first-child) {
    margin-top: x(1);
  }

  &:not(:last-child) {
    margin-bottom: x(1);
  }
}

// File preview

.file_preview {
  @extend %animate-distance;

  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  min-height: 360px;
  box-sizing: border-box;

  &:not(.file_preview--overlay) {
    background-color: var(--bg);

    &:hover,
    &:focus {
      @extend %inset;
    }
  }

  &--overlay {
    max-height: calc(100% - 64px); // Height of top toolbar in overlay
  }

  // Remove bottom margin on file details if file preview is last
  .section__content--case_documents &:last-child {
    margin-bottom: x(-6);
  }
}

iframe.file_preview {
  min-height: 960px;
}

// Added to avoid extra space by Page annotationLayer
.file_preview .react-pdf__Page__annotations {
  display: none;
}

.file_preview__content {
  margin-bottom: x(6);
  max-width: 80%;

  &.is-loading {
    @extend %animation-loading;

    .file_preview__page {
      opacity: 0.65;
    }
  }
}

.file_preview__page {
  @extend %distance-medium;

  background: var(--white);
  margin-top: x(2);
  margin-bottom: x(2);
  min-width: 596px; // Same as IronPDF
  min-height: 596px; // To make a box for loading animation

  .file_preview--overlay & {
    @extend %distance-large;
  }

  &.is-loading {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  &--image {
    background: none;
    min-width: 0;
    min-height: 0;

    > img {
      max-width: 100%;
      display: block;
    }
  }
}

.file_preview__page_count {
  @extend %font-size-small;

  margin-bottom: x(4);
  color: var(--fg-light);
  text-align: center;

  .file_preview--overlay & {
    color: var(--white);
  }
}

// Toasts

div#toast-container .notification,
div#toast-container .toast {
  @extend %distance-large;
  @extend %border-radius-large;

  background-image: none !important; // Remove default icons
  padding: x(1) x(2) calc(#{x(1)} + 1px);
  background-color: var(--bg-message);
  color: var(--fg-message);
  line-height: var(--line-height);
  opacity: 0.92;
}

// Toast notification

div#toast-container .notification {
  background-color: var(--accent);
}

// Toast message (bottom)

div#toast-container .toast {
  padding: x(1) calc(#{x(2)} + var(--icon-1-5)) calc(#{x(1)} + 1px);
  text-align: center;
  min-height: 32px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 30%;
  min-width: 300px;
  max-width: 544px;
}

div#toast-container .toast .toast-message {
  flex: 1 1 auto;
  line-height: var(--line-height);
}

div#toast-container > .toast.toast-success,
div#toast-container > .toast.toast-warning,
div#toast-container > .toast.toast-error {
  background-image: var(--url-svg-success--filled) !important;
  background-size: var(--icon-1-5) var(--icon-1-5);
  background-position: x(1) 50%;
}

div#toast-container > .toast.toast-warning {
  background-image: var(--url-svg-warning--color) !important;
}

div#toast-container > .toast.toast-error {
  background-image: var(--url-svg-error--filled) !important;
  background-color: var(--bg-message-error);
  color: var(--fg-message-error);
}

div#toast-container > .toast.toast-success {
  background-color: var(--bg-message-success);
  color: var(--fg-message-success);
}

// Notification item
// Used in the Notifications panel

.notification_item {
  padding: x(1) x(2);
  background: var(--bg-white);
  border-bottom: 1px solid var(--bdr);

  &--button {
    @extend %focusable;

    cursor: pointer;

    &:hover,
    &:focus {
      background-color: var(--bg);
    }
  }

  &.is-new {
    background-color: var(--bg-card-new);

    &:hover,
    &:focus {
      background-color: var(--bg-card-nav-hover);
    }
  }

  .timestamp {
    @extend %font-size-smaller;

    color: var(--fg);
    opacity: 0.8;
    margin-bottom: 2px;
  }

  .title {
    margin-bottom: x(0.5);
    font-weight: normal;

    .event {
      font-weight: bold;
    }
  }

  .description {
    @extend %font-size-small;

    margin-bottom: x(0.5);
  }

  .note {
    @extend %card-tiny;

    position: relative;
    border-radius: var(--border-radius);
    padding-top: 2px;
    padding-bottom: 2px;
    display: inline-block;
    line-height: var(--line-height);
    margin: 0 x(1) x(0.5) 0;

    span.icon__note {
      content: "";
      color: var(--fg-lighterer);
      float: left;
      margin: 1px 2px 0 0;
      font-size: 20px;
      line-height: 18px;
      text-indent: 0;
      width: var(--icon);
      height: var(--icon);
      background-image: var(--url-svg-note);
    }
  }

  .children {
    @extend %border-radius-large;

    padding: x(0.5) x(0.5) 0;
    background-color: var(--bg);
  }

  &.is-new .children {
    background-color: var(--turqoise-lighterer);
  }

  span.icon.change {
    margin: 0 x(0.5) x(0.5) 0;
  }

  * + span.icon.change {
    margin-left: x(-0.5);
  }
}

.notification_item__close {
  position: absolute;
  top: x(0.5);
  right: x(0.5);

  .notification & path {
    stroke: rgba(var(--fg-white), 0.8);
  }

  &:hover path {
    stroke: var(--fg-link);
  }

  .notification &:hover path {
    stroke: var(--fg-white);
  }
}

// Table

table {
  width: 100%;
}

// temp old striped table

.table-striped > tbody > tr:nth-of-type(odd) {
  background: none;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  vertical-align: middle;
}

.table-striped .btn-toolbar > * {
  margin-bottom: 0;

  &:last-child {
    margin-right: 0;
  }
}

.fnxtable {
  &--flat {
    margin-bottom: x(4);

    // Don't let data in cells get too squished
    th:not(:last-child) > *,
    td:not(:last-child) > * {
      margin-right: x(1);
    }

    #app & .tag {
      text-overflow: unset;
      display: inline-flex; // Fixes cut off
    }

    .td--log {
      @extend %font-size-small;
      @extend %focusable;

      &:hover a,
      &:focus a {
        color: var(--fg-link-hover);
      }

      &:active a {
        color: var(--fg-link-pressed);
      }
    }

    .td--order,
    .td--title,
    .td--created {
      white-space: nowrap;
    }

    .td--checkbox {
      white-space: nowrap;
      text-align: center;
    }

    .checkbox-label {
      width: 140px;
    }

    .btn-toolbar {
      flex-wrap: nowrap;

      > * {
        white-space: nowrap;
      }
    }
  }

  &.is-locked,
  .btn-toolbar.is-locked {
    .td--check,
    .td--main {
      pointer-events: none;
    }

    .multiselect__control,
    input,
    .td--main .option--checkbox:not(.option--confirm_field),
    textarea,
    .text,
    #app & .option--checkbox:not(.option--confirm_field):not(.option--confirm) {
      background-color: var(--bg-disabled);
      box-shadow: none;
    }

    a.destructive,
    .link.is-destructive,
    .btn-toolbar.add_items,
    &.add_items,
    .tr--add {
      display: none;
    }

    .option:not(.option--confirm_field) .option__description::before {
      border-color: var(--bdr-dark);
      background-color: var(--bg-darkerer);
      box-shadow: none;
    }

    .option--confirm > input:not(:checked) + .option__description::before,
    .option--confirm_field
    > input:not(:checked)
    + .option__description::before {
      background-image: var(--url-svg-error--color);
      background-color: transparent;
      border-color: transparent;
      border-radius: 0;
      box-shadow: none;
    }
  }
}

// Tally
// Totals from order table

.tally__wrapper {
  @extend %clearfix;

  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: x(4);
}

.tally {
  width: 35%;
  min-width: 220px;
  max-width: 512px;
  margin: x(2) 0 x(4);

  .td--main {
    text-align: right;

    *:first-child {
      margin-right: x(2);

      &::after {
        content: ":";
      }
    }
  }

  .tr:not(:first-child) .td {
    border-top: 1px solid var(--bdr-light);
  }
}

.azetsTable {
  //Fixing table layout, automatically divides width equally
  table-layout: fixed;
  margin-bottom: 0;

  .td {
    vertical-align: top;
  }

  .td--main {

    *:first-child {
      margin-right: x(2);

      &::after {
        content: ":";
      }
    }
  }

  .td--last {
    @extend %font-size-small;
    text-align: right;
    white-space: nowrap;
    color: var(--fg-light);
  }

  .tr:not(:first-child) .td {
    border-top: 1px solid var(--bdr-light);
  }

  border-bottom: 1px solid var(--bdr-light);
}

.noBorder {
  border-top: 0px solid !important;
}

.table__wrapper {
  .drawer & {
    margin: 0 x(-4) x(6);
  }

  .card .fnxtable--flat &,
  .card & {
    margin: x(2) x(-3);

    .app & tr:not(.tr--sub_products) > td:first-child,
    .app & tr:not(.tr--sub_products) > th:first-child {
      padding-left: x(3);
    }

    .app & tr:not(.tr--sub_products) > td:last-child,
    .app & tr:not(.tr--sub_products) > th:last-child {
      padding-right: x(3);
    }
  }

  tr:not(.tr--sub_products) > td,
  tr:not(.tr--sub_products) > th {
    &:first-child {
      padding-left: x(3);
    }

    &:last-child {
      padding-right: x(3);
    }
  }

  tbody .tr--sub_products > td td,
  tbody .tr--sub_products > td th {
    &:first-child {
      padding-left: x(2);
    }

    &:last-child {
      padding-right: x(2);
    }
  }

  .fnxtable--flat tr:not(.tr--sub_products) > td,
  .fnxtable--flat tr:not(.tr--sub_products) > th,
  div:not(.card) tr:not(.tr--sub_products) > td,
  div:not(.card) tr:not(.tr--sub_products) > th {
    &:first-child {
      padding-left: x(4);
    }

    &:last-child {
      padding-right: x(4);
    }
  }

  &--in_inner {
    margin: 0 x(-4) x(6);

    tr:not(.tr--sub_products) > td,
    tr:not(.tr--sub_products) > th {
      &:first-child {
        padding-left: x(4);
      }

      &:last-child {
        padding-right: x(4);
      }
    }
  }

  &--guests {
    margin-bottom: x(2);
  }

  &--documents {
    margin-top: x(2);
  }
}

.tr {
  &--sub:not(.is-active) {
    .btn-toolbar.sub_product,
    .btn-toolbar.add_items,
    .title a.destructive {
      display: none;
    }
  }
}

.td,
.th {
  padding: x(1);
  vertical-align: middle;
  line-height: var(--line-height);

  &:not(&--select) {
    min-width: var(--touch-target);
  }

  &--select {
    padding-left: 0;
    padding-right: 0;
    width: 48px;
  }

  thead & {
    color: var(--fg-light);
  }

  .fnxtable--flat tbody h5 & {
    margin-bottom: 0;
  }

  .fnxtable--flat tbody & {
    border-bottom: 1px solid var(--bdr-light);

    h5 {
      margin-bottom: 0;
    }

    &--main {
      width: 65%;
    }
  }

  .fnxtable--flat tbody tr:first-child & {
    border-top: 1px solid var(--bdr-light);
  }

  .fnxtable--flat tbody tr:hover &,
  .fnxtable--flat tbody tr:focus & {
    background-color: var(--bg-row-hover);
  }

  .fnxtable--flat tbody .tr--button.is-active & {
    background-color: var(--bg-row-active);
  }

  .fnxtable--flat tbody .tr--button:hover &,
  .fnxtable--flat tbody .tr--button:focus & {
    background-color: var(--bg-row-button-hover);
    cursor: pointer;
  }

  tbody tbody > tr & {
    border-top: 1px solid var(--bdr);
  }

  tbody tbody > tr:first-child & {
    border-top: none;
  }

  .fnxtable--flat tbody &--main {
    width: 65%;
  }

  &--default_main {
    width: 100%;
  }

  &--check {
    width: calc(var(--touch-target) + 2px);
    padding: 0;

    span.icon {
      margin-right: calc(#{x(1)} + 2px);
    }

    #app & .option {
      margin: 0;
    }

    body & .option__description {
      padding: x(1.5) calc(#{x(1.5)} + #{x(1)}) x(1.5) x(1.5);
      text-align: center;
      width: calc(var(--touch-target) + 2px);
      margin-left: x(-1);
    }
  }

  &--main {
    .content {
      margin-top: x(1);
      line-height: var(--line-height);
    }

    .tr:not(.is-active) .btn-toolbar,
    .tr:not(.is-active) .content {
      display: none;
    }

    .background-color & {
      padding-bottom: x(0.5);
    }
  }

  &--price {
    text-align: right;
    white-space: nowrap;

    input {
      margin-right: x(0.5);
      text-align: right;
      margin-bottom: 0;
    }

    .option {
      margin-bottom: 0;
    }
  }

  &--toggle {
    width: x(8);

    .tr--sub_products & {
      width: calc(#{x(8)} - #{x(1)});
    }
  }

  &--toggle_row {
  }

  &--event {
    white-space: nowrap;

    .count {
      @extend %count;
    }
  }

  .title {
    font-weight: 600;
    line-height: 20px;
    margin: 0;

    + div {
      margin: x(1) 0;
    }

    .fnxtable--flat & {
      margin-top: 0;
    }

    input {
      margin-bottom: 0;
    }

    > span {
      vertical-align: middle;
    }

    .count {
      @extend %count;
    }
  }

  &--user {
    white-space: nowrap;

    .avatar {
      margin-right: x(1);
      position: relative;
      vertical-align: middle;
    }
  }

  &--timestamp {
    white-space: nowrap;
    color: var(--fg-light);

    &:hover,
    &:focus,
    tbody tr:hover &,
    tbody tr:focus & {
      color: var(--fg);
    }
  }

  &--rsvp select:only-child {
    margin-right: 0;
  }

  &--name,
  &--phone {
    white-space: nowrap;
  }

  // Sub product wrapper
  &--sub_products {
    padding-top: 0;
    border-top: none;

    > table {
      @extend %border-radius-large;

      background-color: var(--bg-light);
    }
  }

  &.is-empty {
    color: var(--fg-light);
  }

  &.nowrap {
    white-space: nowrap;
  }

  // Display a message instead (empty, error, etc)
  .tr--message &,
  &--message {
    color: var(--fg-light);
  }
}

///
.td {
  &--timestamp {
    @extend %font-size-small;
  }

  &--small {
    @extend %font-size-small;
    color: var(--fg-light);
  }
}

td.td.disabledparent,
tr.disabledparent .td {
  pointer-events: none;

  .multiselect__control,
  textarea,
  .text,
  .option--checkbox:not(.option--confirm_field):not(.option--confirm) {
    background-color: var(--bg-disabled);
    box-shadow: none;
  }

  .option:not(.option--confirm_field) .option__description::before {
    border-color: var(--bdr-dark);
    background-color: var(--bg-darkerer);
    box-shadow: none;
  }

  .option--confirm > input:not(:checked) + .option__description::before,
  .option--confirm_field > input:not(:checked) + .option__description::before {
    background-image: var(--url-svg-error--color);
    background-color: transparent;
    border-color: transparent;
    border-radius: 0;
    box-shadow: none;
  }

  .btn-toolbar.add_items {
    display: none;
  }
}

.tr {
  .fnxtable--flat & {
    border-bottom: 1px solid var(--bdr-light);
  }

  .fnxtable--flat tbody &:hover,
  .fnxtable--flat tbody &:focus {
    background-color: var(--bg-light);
  }

  tbody tbody > & {
    border-top: 1px solid var(--bdr);
  }

  tbody tbody > &:first-child {
    border-top: none;
  }

  tbody &.is-confirmed {
    background-color: var(--green-lightest);
  }

  &--button {
    @extend %focusable;

    cursor: pointer;

    &:hover td {
      background-color: var(--bg-nav-hover);
    }
  }

  &.disabledparent {
    tr .td--main > .btn-toolbar:not(.sub_product),
    a.destructive,
    .btn-toolbar.add_items,
    .tr--add {
      display: none;
    }
  }
}

// Color picker

.tags--color_picker {
  z-index: 2;

  &.show-dropdown .tags__color_picker {
    display: block;
  }

  .tag {
    @extend %animate-distance;

    width: var(--icon-1-5);
    height: var(--icon-1-5);
    padding: 0;
    mix-blend-mode: normal !important;
    position: relative;

    &:nth-child(3) {
      margin-right: 0;
    }

    .icon__drop_down path {
      fill: var(--white);
    }

    .icon__check polyline {
      stroke: var(--white);
    }

    &,
    &--button {
      border-radius: 50%;
    }

    &--button {
      position: relative;
      margin-left: calc(#{x(1)} - 1px);
    }

    &:not(.is-active):hover,
    &:not(.is-active):focus {
      box-shadow: inset 0 0 0 0 var(--bg-white),
      inset 0 0 0 2px var(--fg-assist), 0 1px 2px rgba(var(--black-rgb), 0.1),
      0 1px 4px rgba(var(--black-rgb), 0.1),
      0 2px 8px rgba(var(--black-rgb), 0.1);
      z-index: 2;
    }
  }
}

.tags--color_picker .tag.tag--label::before {
  margin-left: x(0.5);
}

// Color picker dropdown

.tags__color_picker {
  @extend %card-small;
  @extend %distance-medium;

  position: absolute;
  top: x(0.5);
  left: calc(#{x(0.5)} - 1px);
  z-index: 10;
  padding: x(0.5);
  box-sizing: content-box;
  width: calc(var(--icon-1-5) * 3);
  display: flex;
  flex-wrap: wrap;
  display: none; // hide by default

  .tag {
    margin: 0;
  }
}

.tags__color_picker:not(:hover) .tag.is-active {
  cursor: default;
  box-shadow: inset 0 0 0 0 var(--bg-white), inset 0 0 0 2px var(--fg-assist);
}

.add_tag {
  position: relative;
  height: var(--touch-target);
  margin-bottom: x(2);

  &:hover .tags--color_picker svg.icon__drop_down,
  &:focus .tags--color_picker svg.icon__drop_down {
    opacity: 1;
  }

  .tag--label {
    border: none;
  }

  .multiselect {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin-bottom: 0;
  }

  .multiselect .multiselect__control {
    padding-left: var(--touch-target);
    min-height: var(--touch-target);
  }

  .tags--color_picker {
    margin-right: 0;
    z-index: 2;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: var(--touch-target);
    display: flex;
    align-items: center;

    span.icon__drop_down,
    span.icon__check {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 2px 0 0 2px;
    }
  }
}

.tags--color_picker .tag {
  .icon__drop_down path {
    @extend %animate-fill;
  }

  .icon__check polyline {
    @extend %animate-stroke;
  }

  &--orange,
  &--yellow,
  &--green_mint,
  &--grey_light,
  &--pink_hot {
    .icon__drop_down path {
      fill: rgba(var(--black-rgb), 0.95);
      mix-blend-mode: multiply;
    }

    .icon__check polyline {
      stroke: rgba(var(--black-rgb), 0.95);
      mix-blend-mode: multiply;
    }
  }

  // As top level button
  .color_picker > & {
    border: 1px solid;
  }
}

// Overlay

.overlay {
  background-color: rgba(var(--black-rgb), 0.85);
  justify-content: center;

  &--temp {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
  }

  &--white {
    background-color: rgba(white, 1);
  }

  &.is-loading {
    @extend %animation-loading;

    &::before {
      height: 2px;
    }
  }
}

.overlay__toolbar {
  @extend %distance-larger;

  position: relative;
  z-index: 2;
  padding: 0 x(2);
  background: var(--bg-white);

  .inner {
    display: flex;
    justify-content: space-between;

    .btn-toolbar--left {
      min-width: 400px;
    }

    .btn-toolbar > *:last-child {
      margin-right: 0;
    }
  }
}

.modal__wrapper {
  box-shadow: none;
  padding: 0;
  background: none;
}

// React datepicker

.react-datepicker {
  @extend %border-radius;
  @extend %font-family;

  border: 1px solid var(--bdr);
  background: var(--bg-white);
}

.react-datepicker__navigation {
  &,
  &:hover,
  &:focus {
    background: none;
    min-height: 0;
    min-width: 0;
    box-shadow: none;
  }

  &--previous {
    border-right-color: var(--fg-link);

    &:hover {
      border-right-color: var(--fg-link-hover);
    }

    &:focus {
      border-right-color: var(--fg-assist);
    }
  }

  &--next {
    border-left-color: var(--fg-link);

    &:hover {
      border-left-color: var(--fg-link-hover);
    }

    &:focus {
      border-left-color: var(--fg-assist);
    }
  }
}

.react-datepicker__header {
  background-color: var(--bg);
  border-bottom: none;
  border-radius: 2px 2px 0 0;
  cursor: default;

  &--time {
    padding: x(1) x(1.5);
  }
}

.react-datepicker__current-month {
  text-transform: capitalize;
  height: 18px;
  line-height: 18px;
  color: var(--fg);
}

.react-datepicker__month {
  margin: 0 0 x(0.5);
}

.react-datepicker__day-names {
  margin-top: x(0.5);
}

.react-datepicker__week:nth-child(even) {
  background-color: var(--bg-light);
}

// Days
.react-datepicker__time-list-item,
.react-datepicker__day,
.react-datepicker__time-name,
.react-datepicker__day {
  margin: 0;
  padding: x(0.5);
  width: var(--icon-2);
  height: var(--icon-2);
  line-height: calc(var(--icon-2) - #{x(1)});
  color: var(--fg-link);

  &,
  &:hover,
  &:focus {
    border-radius: 0;
  }

  &:hover,
  &:focus {
    background-color: var(--bg-nav-hover);
    color: var(--fg-link-hover);
  }

  &:active {
    background-color: var(--bg-nav-pressed);
    color: var(--fg-link-pressed);
  }

  &--today {
    border: 1px solid var(--bdr-dark);
    margin-top: -1px;
    margin-bottom: -1px;
    color: var(--fg);
    height: var(--icon-2);
    line-height: calc(var(--icon-2) - #{x(1)});
  }

  &--selected,
  &--selected:hover,
  &--selected:focus {
    background-color: var(--bg-assist);
    color: var(--fg-white);
    cursor: default;
  }
}

.react-datepicker__day-name {
  cursor: default;
  margin: 0;
  padding: x(0.5);
  width: var(--icon-2);
  height: var(--icon-2);
  line-height: calc(var(--icon-2) - #{x(1)});
  color: var(--fg-light);
}

.react-datepicker__week-number {
  color: var(--fg-lighter);
  border-right: 1px solid var(--bdr);
  cursor: default;
  margin: 0;
  padding: x(0.5);
  width: var(--icon-2);
  height: var(--icon-2);
  line-height: calc(var(--icon-2) - #{x(0.5)});
}

// Time

.react-datepicker__time-container {
  width: calc(48px * 4);
}

.react-datepicker__time-container .react-datepicker__time {
  padding-bottom: x(0.5);
  background-color: var(--bg-white);
}

.react-datepicker-time__header {
  color: var(--fg);
}

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box {
  width: 100%;
  border-radius: 0;
}

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list {
  height: calc(180px - #{x(0.5)});
  padding-top: x(0.5);
}

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list
li.react-datepicker__time-list-item {
  height: var(--icon-2);
  box-sizing: border-box;
  padding: x(1);
  line-height: calc(32px - #{x(2)});
  float: left;
  width: calc(48px - 3px); /// Until we can sniff browsers macOSi

  &:nth-child(8n + 1),
  &:nth-child(8n + 2),
  &:nth-child(8n + 3),
  &:nth-child(8n + 4) {
    background-color: var(--bg-light);
  }

  &:nth-child(4n + 1) {
    font-weight: bold;
    clear: left;
  }

  &:hover,
  &:focus {
    background-color: var(--bg-nav-hover);
    color: var(--fg-dark);
  }

  &:active {
    background-color: var(--bg-nav-pressed);
  }

  .react-datepicker &--selected,
  .react-datepicker &--selected:hover,
  .react-datepicker &--selected:focus {
    background-color: var(--bg-assist);
    color: var(--fg-white);
    cursor: default;
  }
}

.react-datepicker-popper[data-placement^="top"]
.react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: var(--bdr);
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  border-top-color: var(--bg-white);
}

.react-datepicker-popper {
  z-index: 10;
}

// Stats

.stats {
  display: flex;

  .inner &:not(&--inline) {
    margin-right: x(-4);
    margin-left: x(-4);
  }
}

.stats__stat {
  flex: 1 1 auto;
  text-align: center;

  &:not(:last-child) {
    border-right: 1px solid var(--bdr-light);
  }

  &:not(:first-child) {
    padding-left: x(1);
  }

  &:not(:last-child) {
    padding-right: x(1);
  }

  &.is-bad {
    color: var(--label-red);
  }

  &.is-medium {
    color: var(--label-orange);
  }

  &.is-good {
    color: var(--label-green);
  }

  &--multiline {
    max-width: 50%;
  }

  .description {
    margin-bottom: 0;
    color: var(--fg-light);

    /// Remove when we have tooltips
    &--warning {
      @extend %font-size-small;

      margin: x(0.5) 0 0;

      span.icon {
        margin-right: x(0.5);
      }
    }
  }
}

// Inline list

.inline_list__item {
  display: inline;
  margin-bottom: x(0.5);
  line-height: var(--line-height);

  &:not(:last-child) {
    margin-right: x(1);

    &::after {
      content: ",";
    }
  }
}

// Todos

.todos {
  @extend %list;
}

// Todo

.todo {
  @include list__item(todo);
}

.todo--done .todo__checkbox > input:checked + .option__description::before {
  opacity: 0.8;
}

// Todo checkbox/add ///

.app .todo__checkbox .option__description {
  padding: x(1.5) x(2) x(1.5) 0;
  margin: x(-1.5) 0;
  cursor: pointer;
}

span.todo__add_icon {
  margin-right: calc(#{x(1)} + 2px);
}

// Todo toggle

.todo__toggle {
  @include toggle(todo);

  flex: 0 0 22px;
  border: 1px solid transparent; // Add border first

  div:not(.todo--expanded) & svg * {
    stroke: var(--fg-lighter);
  }

  .todo--expanded & svg * {
    stroke: var(--fg-link);
  }

  svg.icon {
    transition: transform 0.2s ease;
    transform-origin: 50% 50%;

    .todo--expanded & {
      transform: rotate(-180deg);
    }
  }

  &:hover,
  &:focus,
  .todo__head:hover &,
  .todo__head:focus & {
    @extend %button;
    @extend %distance-medium;

    border-radius: 50% 50%;
    padding: 0;
    min-width: 0;
    min-height: 0;

    svg * {
      stroke: var(--fg-link);
    }
  }

  &:focus,
  .todo__head:focus & {
    @extend %focus;
  }
}

// Todo description

.todo__description {
  .todo:not(.todo--done):not(.todo--add):not(.todo--edit):not(.todo--expanded)
  &::first-line {
    font-weight: 600;
  }

  .todo--done:not(.todo--expanded) & {
    @extend %animate-colors;

    color: var(--fg-lighter);
  }

  .todo--done:not(.todo--expanded):hover &,
  .todo--done:not(.todo--expanded):focus & {
    color: var(--fg);
  }
}

// Todo instructions

.todo__instructions {
  @extend %animate-colors;
  @extend %font-size-small;

  flex: 0 0 auto;
  line-height: var(--line-height);
  margin: x(0.5) 0;
  color: var(--fg);

  span.icon__info {
    margin: -2px x(0.5) -2px -3px; // Align better with text

    *[stroke] {
      stroke: var(--fg);
    }

    *[fill]:not([fill="none"]) {
      fill: var(--fg);
    }
  }
}

// Position last fnxtooltip instruction so it doesn't break layout
.todos:last-child .todo:last-child .todo__instructions {
  top: auto;
  bottom: x(2);
}

// Todo metadata and actions (upper right corner)

.todo__meta {
  @extend %font-size-small;
  @extend %animate-colors;

  flex: 0 0 auto;
  margin: (calc(#{x(-1)} - 2px)) (x(1)) (x(-1));
  margin-left: x(1);
  margin-right: x(1);
  float: right;
  color: var(--fg-lighter);
  display: flex;
  align-items: center;

  .todo:hover &,
  .todo:focus & {
    color: var(--fg);
  }
}

.todo__meta__item {
  &:not(&--button) {
    margin-right: x(1.5);

    span.icon {
      margin-right: x(-0.5);
    }
  }

  span.icon {
    padding: x(1);
  }

  svg * {
    @extend %animate-colors;
  }

  span.icon:not(.is-color) *[stroke] {
    stroke: var(--fg-lighter);

    .todo:hover &,
    .todo__head:focus & {
      stroke: var(--fg);
    }
  }

  .app & span.icon circle:last-child {
    fill: var(--fg-lighter);

    .todo:hover &,
    .todo:focus & {
      fill: var(--fg);
    }
  }

  // svg.icon__deadline {
  //   transform-origin: 8px 16px;

  //   .todo:hover &,
  //   .todo:focus & {
  //     animation: wave .5s linear 2;
  //   }
  // }

  // Button
  &--button {
    @extend %focusable;
    @extend %border-radius;

    position: relative;

    .todo:hover &,
    .todo__head:focus & {
      span.icon:not(.is-color) *[stroke] {
        stroke: var(--fg-link);
      }

      span.icon:not(.is-color) *[fill]:not([fill="none"]) {
        fill: var(--fg-link);
      }
    }

    &.is-destructive:hover span.icon:not(.is-color) *[stroke],
    &.is-destructive:focus span.icon:not(.is-color) *[stroke] {
      stroke: var(--btn-tertiary-fg-destructive-hover);
    }
  }

  #app & .fnxtooltip {
    top: 100%;
  }

  .description {
    vertical-align: middle;
  }
}

.todo__meta .todo__instructions {
  @extend %card-small;
  @extend %distance-medium;

  position: absolute;
  z-index: 2;
  right: 0;
  top: x(2);
  width: var(--width-panel);
  pointer-events: none;
}

.fnxtooltip__trigger {
  position: relative;
}

.fnxtooltip {
  pointer-events: none;
  display: none;

  &::before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(var(--bg-white), 0.35);
    z-index: 1000;
  }

  .fnxtooltip__trigger:hover &,
  .fnxtooltip__trigger:focus & {
    display: block;
  }

  > * {
    z-index: 1001 !important;
  }

  > .field,
  > button {
    margin: x(0.5) 0;
  }

  .field:not(:last-child) {
    margin-right: x(1);
  }
}

// Todo add toolbar

.todo--add .todo__head {
  .multiselect {
    margin-right: 0;
  }
}

.todo--add span.icon__add {
  margin-right: 14px;
}

.todo__add_toolbar {
  width: 100%;

  > .multiselect,
  > select {
    margin-top: x(0.5);
    margin-bottom: x(0.5);
  }
}

.field.todo__add_description {
  flex: 1 1 auto;
  position: relative;

  input {
    width: 100%;
  }
}

.todo__input {
  flex: 1 1 auto;
}

.todo__add_description .text + .btn-toolbar__input {
  opacity: 0;
}

.todo__add_description .text:focus + .btn-toolbar__input {
  opacity: 1;
}

// Tooltip

[data-tip="true"] {
  position: relative;
}

.fnxtooltip {
  pointer-events: none;
  position: absolute;
  z-index: 100;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:not(&--align_top) {
    top: calc(100% + #{x(0.5)});
  }

  &--align_top {
    /// not used?
    bottom: calc(100% + #{x(0.5)});
  }

  // Position closer if inline
  &--inline:not(&--align_top) {
    top: 100%;
  }

  &--inline.fnxtooltip--align_top {
    /// not used?
    bottom: 100%;
  }

  // Don't center if inline
  &:not(&--inline) {
    right: -100%; // To allow for wider tooltips than parent object
    left: -100%; // -*-
  }

  // Multiline instructions
  &--multiline:not(&--inline) {
    white-space: normal;
    right: -1000%; // Give plenty of space
    left: -1000%;

    .fnxtooltip__inner {
      max-width: 320px;
    }
  }

  // If parent is only button with icon
  .button--no_label & {
    right: -200%;
    left: -200%;
  }

  // Align right
  #app &--align_right {
    right: x(0.5);
    left: auto;
  }

  // Align left
  #app &--align_left {
    right: auto;
    left: x(0.5);
  }
}

.fnxtooltip__inner {
  @extend %distance-medium;
  @extend %font-size-smaller;
  @extend %border-radius;

  margin: 0 auto;
  padding: 2px x(0.5);
  background-color: rgba(0, 0, 0, 0.75);
  color: var(--white);
  display: inline-block;
  opacity: 0;
  line-height: 13.2px; // make line breaks better
  padding-bottom: 3px; // fMake text optically align better

  .has-action:hover .btn-toolbar:not(:hover) .is-primary-action &,
  .has-action:focus .btn-toolbar:not(:hover) .is-primary-action &,
  .has-action:hover .btn-toolbar:not(:hover) .is-primary-action + .fnxtooltip &,
  .has-action:focus .btn-toolbar:not(:hover) .is-primary-action + .fnxtooltip &,
  [data-tip="true"]:hover &,
  [data-tip="true"]:focus &,
  [data-tip="true"]:hover + .fnxtooltip &,
  [data-tip="true"]:focus + .fnxtooltip & {
    @extend %animation-reveal;
  }

  > span + .shortcut_icon {
    margin-right: -2px;
    margin-bottom: -1px;
    line-height: 11.2px;
  }

  .count {
    margin-right: 2px;

    & + .content {
      text-transform: lowercase; // If true, make the text flow naturally
    }
  }
}

.fnxtooltip__description {
  display: inline-block;
}

// NPS

.nps,
.fnxtable--nps {
  .is-detractor {
    color: var(--label-red);
  }

  .is-passive {
    color: var(--label-orange);
  }

  .is-promoter {
    color: var(--label-green);
  }

  .is-empty {
    color: var(--fg-light);
  }
}

.nps-style {
  white-space: pre-line;
}

.card.nps .card__title {
  margin-bottom: x(1);
}

.nps__class {
  margin-left: x(1);
}

.nps__details {
  margin-bottom: x(0.5);
}

.nps__contact {
  font-weight: 600;
}

.nps__comment:last-child {
  margin-bottom: 0;
  white-space: pre-line;
}

// Compensation

.compensation {
  margin-bottom: x(4);

  .section__column__title {
    margin-bottom: 0;

    .value {
      color: var(--fg-green);
      margin-left: x(0.5);
    }
  }

  .stats {
    margin-top: x(4);
  }
}

.compensation__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// Log item list

.log_item__list > .table__wrapper {
  overflow-x: auto;
}

// Log item

.log_item {
  background: var(--bg-white);

  &--button:not(.log_item--expanded) .content--removed {
    display: none;
  }

  &:not(:hover) .content--removed {
    text-decoration: line-through;
  }

  .tag {
    margin-bottom: 0;
    vertical-align: top;
  }

  /// don't show current object in trace
  /// todo: just don't output current object
  #app .fnxtable--flat &--case .tag--case {
    display: none;
  }

  .td {
    vertical-align: top;

    &--timestamp span {
      vertical-align: middle;
      margin-bottom: x(0.75);
    }
  }

  .fnxtable--log &--button {
    cursor: pointer;

    &:hover .td {
      background-color: var(--bg-row-button-hover);
    }
  }
}

// Inline tips

.inline_tips {
  float: right;

  .link {
    .icon svg:not(.is-color) *[stroke] {
      stroke: var(--fg-link);
    }

    .icon__info svg:not(.is-color) circle:last-child {
      fill: var(--fg-link);
    }

    &:hover .icon svg:not(.is-color) *[stroke] {
      stroke: var(--fg-link-hover);
    }

    &:hover .icon__info svg:not(.is-color) circle:last-child {
      fill: var(--fg-link-hover);
    }

    &:active .icon svg:not(.is-color) *[stroke] {
      stroke: var(--fg-link-pressed);
    }

    &:active .icon__info svg:not(.is-color) circle:last-child {
      fill: var(--fg-link-pressed);
    }
  }
}

// Empty message
/// move?

.fnxtable--flat tbody .tr--message {
  &:hover,
  &:hover .td {
    background-color: transparent;
  }

  .td--message {
    height: x(8);
    vertical-align: middle;
    text-align: center;
  }
}

// Log item tag specifics

.log_item .tag {
  .avatar {
    margin: -1px x(0.5) -1px -2px;
    float: left;
  }

  &--owner .avatar::before {
    border-color: transparent;
  }
}

// Log item details

.log_item__details {
  // Align on same line if multiline and not expanded
  .log_item--button:not(.log_item--expanded) & {
    align-items: baseline;
  }

  .trace {
    .log_item--button:not(.log_item--expanded) & {
      float: left;
      margin-right: x(1);
    }

    .tag {
      white-space: nowrap;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .content {
    flex: 1 1;
    margin-top: 0;
    white-space: pre-line;

    .dd {
      word-wrap: break-word;
      overflow-wrap: break-word;
      word-break: break-word;
    }

    .log_item--button:not(.log_item--expanded) &:not(&--removed) {
      &,
      dl {
        display: inline;
      }
    }
  }
}

// Align on separate lines if it's a single value item
// or if it's an expanded multiline item
.log_item:not(.log_item--button) .trace + .content,
.log_item--button.log_item--expanded .trace + .content {
  margin-top: x(1);
}

// Log item action
// Really only used for (avatar) user currently

.log_item__action {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;

  .avatar + span {
    margin-left: x(1);
  }

  .avatar span.icon {
    display: block;
  }
}

// Log item toggle

.log_item__toggle {
  width: 22px;
  height: 22px;
  flex: 0 0 22px;

  .th & {
    @include toggleAll(fnxtable);

    border: 1px solid transparent; // Add border first
    display: flex;
  }

  .td & {
    @include toggle(log_item);

    display: flex;
  }
}

// Changeset (dl)
.changeset {
  display: flex;
  flex-wrap: wrap;

  .log_item--button:not(.log_item--expanded) & {
    display: inline;
  }

  .log_item--expanded & {
    margin: x(-0.5) 0;
  }

  .tags + & {
    margin-top: x(0.5);
  }
}

.log_item--expanded,
.log_item.tr--active {
  .dt,
  .dd {
    padding: x(0.5) 0;
  }
}

// Changeset term (dt)

.changeset .dt {
  font-weight: 500; // Reset bootstrap

  // If expandable, display items inline
  .log_item--button:not(.log_item--expanded) & {
    display: none;

    &:not(:last-of-type)::after {
      content: ", ";
    }
  }

  // If not expandale or expandable and expanded
  .log_item:not(.log_item--button) &,
  .log_item--expanded & {
    display: block;
    flex: 1 0 35%;

    &:not(:first-of-type) {
      border-top: 1px solid var(--bdr-light);
    }
  }

  .log_item--button .changeset--email & {
    display: none;
  }
}

// Changeset details (dd)

.changeset .dd {
  // Don't display by default if expandable
  .log_item--button & {
    display: none;
  }

  // If not expandale or expandable and expanded
  .log_item:not(.log_item--button) &,
  .log_item--expanded & {
    flex: 1 1 65%;
    display: flex;

    &--long {
      flex: 1 1 100%;
    }

    &:not(:first-of-type) {
      border-top: 1px solid var(--bdr-light);
    }
  }
}

// Key/Value pairs logic

.key,
.value {
  padding: 2px 0;
  line-height: 14px;
  display: flex;
  align-items: center;
  line-height: var(--line-height);
}

.value {
  @extend %border-radius;

  box-sizing: border-box;
  text-align: left;
  min-width: 20px; ///

  & + .value {
    margin-left: x(1);
  }

  &--old:not(.value--placeholder) {
    &,
    .tag,
    .tag span,
    a {
      text-decoration: line-through;
    }
  }

  &--placeholder {
    color: var(--fg-light);
  }

  .dd:not(.dd--long) &--new {
    &,
    & .tag {
      font-weight: bold;
    }
  }

  &--change {
    flex: 0 0 auto;
  }

  .tag:last-child {
    margin-right: 0;
  }
}

// Case > Memorial
// Special styles for the case memorial page

.section--memorial {
  .background_picture,
  .deceased_picture {
    float: right;

    img {
      border-radius: 10px;
    }
  }

  .fund_logo {
    @extend %border-radius-large;

    background: var(--white); ///
    max-width: 65%;
    padding: x(1);
    margin-bottom: x(1);

    img {
      max-width: 100%;
      height: auto;
    }
  }

  /// Clumsy
  .btn-toolbar label .count {
    @extend %count;
  }
}

// Empty
// Simple boxed empty messages

.empty {
  @extend %card;
  @extend %card-inverted;

  padding: x(2);
  margin-bottom: x(2);

  .title {
    margin-bottom: 0;
  }

  .description {
    margin-bottom: 0;

    .title + & {
      margin-top: x(2);
    }
  }
}

// Memories, memory

.memory {
  @extend %card-small;

  margin-bottom: x(2);

  &.has-action:not(:hover) {
    @extend %card-inverted;
  }

  &.has-action:hover {
    @extend %focusable;
    @extend %distance-medium;

    .btn-toolbar {
      cursor: default;
    }
  }

  /// remove when .toolbar exists
  .btn-toolbar .right > .button.is-tertiary {
    margin-right: 0;
  }

  // Likes custom
  .btn-toolbar .static--likes {
    display: flex;
    white-space: nowrap;
    flex-wrap: nowrap;
    align-items: center;

    span.icon {
      margin-right: x(0.5);
    }
  }

  &.is-loading {
    @extend %animation-loading;
  }

  &.is-success,
  &.is-warning,
  &.is-error {
    border: 1px solid var(--fld-bdr-error);
  }
}

.memory__content {
  white-space: pre-line;

  .memory__head + & {
    margin-top: x(2);
  }
}

.memory__picture {
  @extend %border-radius-large;

  float: right;
  max-width: 35%;
  height: auto;
  margin: 0 0 x(2) x(2);
}

.memory__foot {
  clear: both;

  .memory__content + & {
    margin-top: x(4);
  }

  .timestamp {
    @extend %font-size-small;

    opacity: 0.8;
    flex: 1 1 auto;
  }

  .btn-toolbar--foot {
    margin-bottom: x(-1.5);
  }

  .right {
    flex: 0 0;
    flex-direction: row;
    align-items: center;

    .button {
      white-space: nowrap;
    }

    .tag {
      display: inline-flex;
      height: var(--line-height);
      box-sizing: content-box;
    }

    .memory--edit & {
      flex: 1 0 auto;
      justify-content: flex-end;
    }
  }
}

// Email subscriptions, email subscription

.email_subscriptions {
  @extend %list;
}

.email_subscription {
  @include list__item(email_subscription);
  position: relative;

  &.is-loading {
    @extend %animation-loading;
  }

  /// remove when .toolbar exists
  .email_subscription__meta .btn-toolbar > * {
    margin-right: 0;
    margin-bottom: 0;
  }

  &--edit,
  &--add {
    .email_subscription__description {
      flex-direction: column;
      align-items: stretch;
    }

    .btn-toolbar:only-child {
      flex: 1 1;
      margin-bottom: x(-1.5);
    }

    // Align error message better
    .message {
      flex: 1 1;
      margin-left: calc(var(--x) * -1);
    }
  }
}

//'div's' disabled property added
div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.is-helper {
  color: var(--label-red);
}

/// temp_order_event_attributes

#app .temp_order_event_attributes {
  margin-top: x(3);
  display: grid;
  grid-template-columns: repeat(auto-fill, 360px);
  grid-gap: x(2);
  justify-items: start;
  align-items: start;
}

// --probate section
.d-flex {
  display: flex;
}

.margin-left {
  margin-left: calc(var(--x) * 1.5);
}

.probate-beneficiaries, .probate-information, .probate-notes, .probate-invoices, .probate-assets-debts, .summary-assets {
  border: 1px solid var(--bdr);
  padding: calc(var(--x) * 1.5);
}

.add-border {
  border: 1px solid var(--bdr);
  padding: calc(var(--x) * 1.5);
  margin-bottom: x(2);
}

.card-note {
  @extend %card-small;

  display: flex;
  flex-direction: column;
  margin-top: x(1);

  .content {
    flex: 1 1 auto;
    line-height: var(--line-height);
    white-space: pre-line;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-word;
  }

  &--add-border {
    border: 1px solid var(--bdr);
    padding: calc(var(--x) * 1);
    margin-bottom: x(1);
  }
}

.full-width {
  max-width: 100%;
}

.probate-invoices {
  .d-flex {
    .option__description {
      padding-right: 18px;
    }
  }
}

.react-datepicker__day {
  cursor: pointer;
}

.react-tabs__tab {
  cursor: pointer;
  padding: 10px 12px;
}

.react-tabs__tab--selected {
  border-radius: 0px;
  border-color: var(--bdr-dark);

  &:not(.is-active):not(:hover):not(:focus) {
    background-color: var(--bg-nav-pressed);
    color: var(--fg-dark);
  }

  &.is-active {
    background-color: var(--bg-nav-selected);
    color: var(--fg);
  }
}

.react-tabs__tab-list {
  border-bottom: 1px solid var(--bdr-dark);
}

.probate-notes, .probate-information {
  ul {
    li {
      padding-bottom: 12px;
    }
  }
}

.hideDiv {
  display: none !important;
}

// global column search
.global-searchBtn {
  display: flex;

  .single_search {
    width: 70%;
  }

  .global_search {
    width: 30%;
    margin-left: 12px;
  }
}

.global-searchBtn.hide-global-search-btn {
  .single_search {
    width: 100%;
    margin-left: 0px;
  }

  .global_search {
    display: none;
  }
}

.column--single {
  .global-searchBtn {
    .single_search {
      width: 100%;
    }

    .global_search {
      display: none;
    }
  }
}

.drag__content__list {
  cursor: move;
}

//date picker icon placement
.react-datepicker__close-icon::after {
  font-size: 30px;
  cursor: pointer;
  background-color: transparent;
  -webkit-text-stroke: 0.6px #1f2122;
}

.cal-rem-btn {
  .react-datepicker__close-icon {
    top: -7px;
  }
}

.product_field {
  .multiselect__clear-indicator {
    display: none;
  }
}

// date picker clear mark && calendar pointer
.multiselect__clear-indicator, .react-datepicker__day {
  cursor: pointer;
}

// password-Instruction-Message
.password-instruction-message {
  ul {
    padding-left: 20px;

    li {
      list-style-type: disc;
      padding: 5px 0px;
    }
  }
}

// funeralPopup
.popup_funeral_popup {
  .btn-toolbar {
    padding-bottom: 15px;
  }

  .funeral-buttons,
  .funeral-actionbuttons {
    .button {
      margin-right: 10px;
    }
  }
}

.funeral-buttons {
  width: 70%;
}

.funeral-buttons,
.funeral-actionbuttons {
  display: flex;
}

.funeral-actionbuttons {
  width: 30%;
  margin-left: auto;
}

.question-row {
  display: flex;
  margin-bottom: 12px;

  .ques-name {
    width: 70%;

    h4 {
      font-weight: 400;
    }
  }

  .ques-action {
    input[type="radio"] {
      opacity: 1;
    }

    width: 30%;
    margin-left: auto;
    display: flex;

    .label-no {
      margin-left: 0px;
    }

    .que-unknown {
      margin-left: 35px;
    }

    div {
      &:nth-child(1), &:nth-child(2) {
        .chkbox_body {
          padding-left: 15px;
        }
      }

      &:nth-child(1) {
        flex: 1 1 0;
      }

      &:nth-child(2) {
        flex: 1 1 0;
      }

      &:nth-child(3) {
        flex: 1 1 0;
        margin-left: 10px;
      }
    }
  }

  .rem-btm-margin {
    div {
      h4 {
        margin-bottom: 0px;
      }
    }
  }

  &:nth-child(3) {
    margin-bottom: 0px;

    .ques-name h4 {
      margin-bottom: 0px;
    }
  }

  &:nth-child(4) {
    .ques-name h4 {
      margin-left: 2rem;
    }
  }
}

.unchecked.disabled-radio-btn {
  .checkmark {
    background-color: var(--grey-light) !important;

    &:after {
      border: 1px solid var(--grey-light) !important;
    }
  }
}

.unchecked {
  .checkmark {
    background-color: var(--white) !important;

    &:after {
      border: 1px solid var(--white) !important;
    }
  }
}

.checked {
  input[checked] + .checkmark {
    background-color: var(--blue);

    &:hover {
      background-color: var(--blue);
    }
  }

  input[checked] + .checkmark:after {
    display: block;
  }

  .checkmark {
    &::after {
      left: 6px;
      top: 2px;
      width: 6px;
      height: 11px;
      border: solid var(--white);
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}

// order page collapse button
.td.collapse-btn {
  margin: auto;
}

// document section
@mixin common-margin {
  margin-top: calc(var(--x) * 1);
  margin-bottom: 0px;
}

.table-fixed {
  td {
    vertical-align: middle;

    .p-wrap {
      max-width: 500px;
      word-wrap: break-word;
      white-space: pre-wrap;
      text-align: justify;
      color: var(--fg-light);
      @include common-margin;
    }
  }
}

.doc-details {
  .section__title {
    display: flex;

    span {
      display: flex;

      p {
        margin-left: calc(var(--x) * 0.5);
        @include common-margin;
      }
    }
  }

  p.section__subtitle.has-icon {
    @include common-margin;
  }
}

// message page 
.case--message-section {
  .add_note {
    margin-top: calc(var(--x) * -1);
  }

  img {
    height: calc(var(--x) * 3);
    width: calc(var(--x) * 3);
    border: 1px solid var(--bdr-black);
    border-radius: 50%;
    margin-right: calc(var(--x) * 0.4);
  }

  .right-align {
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .content {
    text-align: justify;
    margin-bottom: calc(var(--x) * 3);
  }

  .message--order-list {
    width: 30%;
    margin-right: 0px;
    margin-left: 0px;
    max-width: 400px;
  }

  .message--form {
    width: 70%;
    max-width: 70%;
    margin: 0px;
  }

  .message--header-sec {
    margin: 0 calc(var(--x) * 0);
  }
}

.message--order-list {
  .option--radio {
    margin-top: calc(var(--x) * 0.5) !important;
    padding: 0 calc(var(--x) * 0.2);
    margin-left: calc(var(--x) * 0.5);
  }

  .option__description {
    display: flex;
    margin-right: calc(var(--x) * 1);

    .case--order-title {
      width: 70%;
    }

    .case--message-orderID {
      width: 15%;
      margin-left: 2%;
    }

    .case--message-unread {
      min-width: 7%;
      margin-left: 2%;
      margin-right: 2%;
      border: 1px solid var(--grey);
      height: 7%;
      border-radius: 50%;
      padding: 1.5px;
      text-align: center;
    }
  }

  .option__description::before {
    width: 19px !important;
  }

  input[type=radio] + .option__description::before {
    border-radius: 100%;
  }

  input[type=radio]:checked + .option__description::before {
    background-color: var(--opt-bg-checked);
    box-shadow: inset 0 0 0 2px var(--opt-bg);
    border-color: var(--opt-bdr-checked);
    border-radius: 100%;
  }

  ul {
    list-style: none;
    // height: 164px;
    // overflow-y: scroll;
  }
}

.message--refresh-list {
  height: 50px;
  padding: 0px !important;
  border: 1px solid var(--bdr);
  max-width: 100%;

  .section__row {
    display: flex;
    justify-content: space-between;
    padding: calc(var(--x) * 4);
    padding-top: 2px;
    align-items: center;

    h5 {
      margin-bottom: 0px;
    }
  }
}

//general probate fixes
.react-datepicker__close-icon {
  top: -7px;
}

// probate-information
.case--probate-body {
  .inner, .section__column {
    max-width: 100%;
  }
}

.probate-information {
  .td--actions {
    padding: 0px var(--x);
  }

  tr {
    border-bottom: 1px solid var(--grey-lighter);

    &:last-child {
      border-bottom: 0px;
    }
  }
}

.probate-assets-debts, .probate-information {
  .icon {
    margin: 0 calc(var(--x) * 0px) 0 calc(var(--x) * 0px) !important;
  }

  a.link.is-external {
    padding-top: 2px;
  }

  .form-group:not(.form-group__column) {
    margin-top: 0px;
  }
}

.probate-assets-debts {
  .summary--assets-alignment {
    max-width: 720px;

    table {
      width: 720px;
    }

    tr {
      th {
        text-align: right;

        &:first-child {
          text-align: left;
        }
      }
    }

  }
}

.summary-assets {
  margin-bottom: calc(var(--x) * 1.5);

  input, select, textarea {
    margin-bottom: calc(var(--x) * 0);
  }

  .summary--add-btn {
    margin-top: calc(var(--x) * 1.5);
    padding-left: calc(var(--x) * 1);
  }

  table {
    width: 100%;
  }

  .table > thead > tr > th {
    border-bottom: 1px solid var(--grey-lighter) !important;
  }
}

@mixin button-property {
  min-height: 0px;
  cursor: pointer;
  min-width: 0px;
  box-shadow: unset !important;
  border: 0px !important;
  padding: 2px var(--x) !important;
  span.SVGInline.icon {
    margin: 0 calc(var(--x) * 0) 0 calc(var(--x) * 0) !important;
  }
}

.probate--document {
  .form-group__column {
    flex: 0;
    padding-right: calc(var(--x) * 0);
  }

  .form-group__column:last-child {
    margin-top: 0px;
  }

  .button {
    @include button-property;
    padding: 2px 0px !important;
  }
}

.probate--due-date {
  display: flex;

  .form-group.form-group--static {
    width: 63%;
  }

  .react-datepicker__close-icon {
    top: -4px;
  }

  .button--flex {
    margin-top: calc(var(--x) * 1);
    margin-bottom: calc(var(--x) * 1);
    margin-left: calc(var(--x) * 0.5);
    margin-right: calc(var(--x) * 0.5) !important;
  }

  .icon {
    margin: 0 calc(var(--x) * 0px) 0 calc(var(--x) * 0px) !important;
  }

  button#undoDateChange {
    margin-left: 0px;
  }

  .form-group.form-group--columns {
    margin-top: calc(var(--x) * 2.5);
  }

  a.link.is-external {
    padding-top: calc(var(--x) * 2);
  }

  p.static {
    margin-right: 0px !important;
    margin-top: calc(var(--x) * 1.5);
  }

  .button {
    padding: 0px calc(var(--x) * 1) !important;
  }

  .td--order {
    .tag {
      margin-right: 0px;
    }
  }

  .btn-toolbar {
    flex-wrap: inherit;
  }

  .image--btn-group {
    width: 37%;

    .form-group--columns {
      width: 100%;
    }

    .td--order {
      margin-top: calc(var(--x) * 1);
    }

    .button.is-small {
      min-height: 30px;
      border: 0px !important;
      box-shadow: unset !important;
    }
  }
}

.probate-beneficiary-table {
  table-layout: fixed;
  width: 1700px;
  overflow: scroll;

  .multiselect {
    min-width: 100%;
    padding: 5px;
    margin: auto;
  }

  td .multiselect {
    font-size: 13px;
    text-align: left;
    min-width: 100px;
  }

  .form-group__column:empty {
    display: none;
  }

  th.th.th--author {
    text-align: center;
    font-size: 13px;
    height: 191px;
    vertical-align: middle;
    white-space: nowrap;
    z-index: 1;
  }

  .table--select {
    min-width: 100%;
    padding: 0px;
    margin: auto;

    .multiselect__control {
      width: 100%;
      margin: 0px;
    }
  }

  .table--divider {
    padding: 5px;

    .table--select {
      margin: inherit;
      min-width: 55%;
    }
  }

  td {
    label {
      padding-bottom: calc(var(--x) * 0);
    }

    border: 1px solid var(--grey-lighter);
    padding: 4px 0px;
    text-align: center;
    vertical-align: middle;
  }

  .beneficiary--document.document--id {
    width: 25%;
  }

  .beneficiary--document.beneficiary--renounced-inheritance {
    width: 85%;
  }

  .beneficiary--document {
    width: 50%;
    padding: 4px;
    margin: auto;

    .form-group__column {
      flex: 0;
      z-index: 9;
      padding-right: calc(var(--x) * 0);
    }

    .form-group__column:last-child {
      margin-top: 0px;
    }

    .button {
      @include button-property;
      padding: 2px 0px !important;
    }

  }

  .beneficiary--btn-group {
    display: flex;
  }

  .beneficiary--datepicker {
    .field--min_width {
      width: 100%;
      padding: 5px;
    }
  }

  .tb-col-5 {
    width: 20%;
    transform: translate(22%, 28px) rotate(315deg);

    div {
      transform: translate(-62px, 0%);
    }
  }

  .tb-col-2 {
    transform: translate(50%, 63px) rotate(315deg);
    width: 5%;

    div {
      transform: translate(-50%);
    }
  }

  .tb-col-2 {
    transform: translate(50%, 60px) rotate(315deg);
    width: 5%;

    div {
      transform: translate(-22px, 0%);
    }
  }

  .tb-col-6 {
    transform: translate(50%, 23px) rotate(315deg);
    width: 15%;

    div {
      transform: translate(-48px, 0%);
    }
  }

  .tb-col-10 {
    transform: translate(30%, 0px) rotate(315deg);
    width: 25%;

    div {
      transform: translate(-76px, 0%);
    }
  }

  .wantToParticipateInventory {
    width: 4%;
    transform: translate(50%, 64px) rotate(315deg);

    div {
      transform: translate(-22px, 0%);
    }
  }

  .haveInventoryInvitation {
    transform: translate(50%, 60px) rotate(315deg);
    width: 4%;

    div {
      transform: translate(-22px, 0%);
    }
  }

  .tb-col-10.calltoEvent {
    transform: translate(30%, -2px) rotate(315deg);

    div {
      transform: translate(-68px, 0%);
    }
  }

  .tb-col-9.confirmedCalledOfEvent {
    width: 25%;
    transform: translate(50%, -26px) rotate(315deg);

    div {
      transform: translate(-68px, 0%);
    }
  }

  .td-col-5.renounceInheritance {
    width: 12%;
    transform: translate(50%, 0px) rotate(315deg);

    div {
      transform: translate(-54px, 0%);
    }
  }

  .td-col-3.isEstateExecutor {
    width: 5%;
    transform: translate(50%, 54px) rotate(315deg);

    div {
      transform: translate(-25px, 0%);
    }
  }

  .tb-col-3.worksForFenix {
    width: 5%;
    transform: translate(50%, 51px) rotate(315deg);

    div {
      transform: translate(-28px, 0%);
    }
  }

  .td-col-2.emptyTitle {
    width: 4%;
    transform: translate(50%, 0px) rotate(315deg);

    div {
      transform: translate(-25px, 0%);
    }
  }

  .react-datepicker__close-icon {
    top: -4px;
  }
}

@for $i from 1 through 10 {
  .tb-col-#{$i} {
    width: ($i * 2%);
  }
}

// questionnaire popup
.modal--popup-body {
  display: block;
}

.modal--left-sidebar {
  width: 100%;
}

.modal--right-sidebar {
  display: none;
}

.questionnaire--child {
  padding: 16px 0px 16px 16px;
  display: flex;

  .questionnaire--icon {
    margin-top: calc(var(--x) * -1);
  }

  .questionnaire--body {
    width: 100%;
    padding-left: var(--x);

    .option--checkbox {
      margin-top: 0px !important;
    }
  }
}

.case--message-section {
  .option {
    margin-bottom: 0px !important;
  }

  .inner {
    margin-top: calc(var(--x) * 1);
  }

  .note.right-align {
    border-bottom-right-radius: 0px;
  }

  input[type=radio] {
    opacity: 0 !important;
    cursor: pointer;
    z-index: 999;
    margin-top: calc(var(--x) * -1.5);
    margin-left: 0px;
  }

  input[type=radio] {
    position: relative;
    top: 20px;
    height: 25px;
    width: 100%;
  }

  .note.message-list.right-align {
    margin-left: 36px;
    margin-right: 0px;
    border-left: 0px;
    border-right: 3px solid var(--turqoise);
    border-bottom-right-radius: 0px;
    border-top-left-radius: 4px;
  }

  .note.message-list {
    border-left: 3px solid var(--orange);
    margin-right: 36px;
    margin-left: 0px;
    border-top-left-radius: 0px;
  }

}

.message--not-found {
  .content {
    margin-bottom: 0px;
    display: flex;
  }

  h6 {
    margin-top: calc(var(--x) * 1.2);
    margin-bottom: calc(var(--x) * 1);
    color: var(--fg);
  }
}

.message-notices {
  span {
    padding-right: calc(var(--x) * 1);
  }
}

.section__content.section__content--case_details.case--message-section {
  padding-top: 0px;
  padding-bottom: 0px;
}

.editedStatus {
  font-weight: 900;
  color: var(--turqoise);
  padding-left: calc(var(--x) * 1);
}

span.messageSeen {
  padding-left: calc(var(--x) * 1);
}

@mixin leftsidebar-common {
  padding: calc(var(--x) * 3);
  height: 85vh;
  max-height: 100%;
  overflow-y: auto;
  position: relative;
}

.modal__with-sidebar {
  max-width: 1024px;

  .card {
    padding: 0px;
  }

  .modal--popup-body {
    display: flex;
  }

  .modal--left-sidebar {
    width: 70%;
    @include leftsidebar-common;
  }

  .modal--right-sidebar {
    width: 30%;
    background-color: var(--grey-lighter);
    border-top-right-radius: var(--border-radius-large);
    border-bottom-right-radius: var(--border-radius-large);
    margin-bottom: calc(var(--x) * -9);
    display: block;
  }

  .questionnaire--list {
    padding: calc(var(--x) * 3);
  }

  .btn-toolbar--modal {
    margin: 0px;
  }
}

.modal__without-sidebar {
  max-width: 100%;

  .card--modal {
    min-width: calc(var(--width-min));
  }

  .card {
    padding: 0px;
  }

  .modal--left-sidebar {
    width: 100%;
    @include leftsidebar-common;
  }

  .btn-toolbar--modal {
    margin: 0px;
  }
}

.documentation__modal {
  .card--modal {
    min-width: calc(var(--width-column) * 2.5);
  }

  .inner {
    padding: 0px;
  }

  aside {
    margin: 0px !important;
  }
}

.questionnaire--input-group {
  background: none !important;
  box-shadow: inset 0 0 0 0px var(--fld-bdr) !important;

  .upload--button {
    border: 1px solid var(--fld-bdr);
    margin-top: 32px !important;
    background: #ffff;
    border-left: 0px;
  }

  .button {
    margin: calc(var(--x) * 0.4) !important;
  }
}

.form-group--columns {
  &:last-child {
    padding-right: calc(var(--x) * 0);
  }
}

.remove--right-pad {
  padding-right: 0px;
}

.drawer.questionnaire--add-person {
  margin-top: calc(var(--x) * 0);
  background-color: transparent;
  padding: calc(var(--x) * 1.5) 0px;
  margin-bottom: calc(var(--x) * 2);

  button {
    margin-bottom: 0px;
  }
}

.notes--doc-upload {
  .form-group__column {
    padding-right: 0px;
    width: auto;
    flex: 1;
  }

  .td--order {
    margin-top: 2px;
  }

  .button {
    padding: 2px 0px !important;
    min-height: 0px;
    margin: 0px;
    min-width: 0px;
    margin-top: 2px;
    border: 0px !important;
    box-shadow: 0px !important;
    box-shadow: unset !important;
  }

  .icon {
    margin: 0 calc(var(--x) * 0.5) 0 calc(var(--x) * 0px) !important;
  }
}

.table-scroll {
  position: relative;
  max-width: 900px;
  margin: auto;
  overflow: auto;

  .probate-beneficiary-table {
    margin: auto;
    border-spacing: 0;
  }
}

// funeral popup-upgrade
.package_section {
  display: flex;
  padding-top: calc(var(--x) * 2);

  .section__title {
    padding-top: 3px;
    margin-left: calc(var(--x) * 5);
  }

  .package-upgrade-btn {
    margin-left: calc(var(--x) * 2);
  }
}

#upgradeStatus {
  margin-left: calc(var(--x) * 5);
}

.message--link {
  button {
    margin-left: calc(var(--x) * 1);
    border: 0px !important;
    padding: 0px !important;
    margin-top: 0px;
    padding-top: 20px;
    top: calc(var(--x) * 0.8);
  }

  .icon__message {
    margin: 0px !important;
  }
}

.loading-status {
  .placeholders {
    height: 110px;
  }
}

.contact--invited-customer {
  .contact--ordersList {
    li {
      display: flex;
      flex: 1;
      margin-bottom: calc(var(--x) * 1.5);
    }

    p {
      margin-bottom: 0px;
      padding-right: calc(var(--x) * 2);
    }

    .accessLevel {
      font-weight: 600;
    }

    a {
      padding-right: calc(var(--x) * 2);

      span {
        padding-top: 2px;
      }

      span.icon {
        vertical-align: bottom;
      }
    }

    .button.is-small {
      padding-right: calc(var(--x) * 2);
      min-height: 0px;
      min-width: 0px;
      border: none;
      box-shadow: none;
    }
  }
}

.questionnaire--form {
  background-color: #edf0f0;
  padding: calc(var(--x) * 2.5);
  border-radius: calc(var(--x) * 0.5);
  margin-bottom: calc(var(--x) * 0.5);

  .rem-margin {
    margin-bottom: 0px;
  }

  &.is-error {
    border: 1.4px solid var(--fld-bdr-error);
  }

  &.is-warning {
    border: 1.4px solid var(--fld-bdr-warning);
  }
}

.questionnaire--head {
  background: #edf0f0;
  margin-bottom: calc(var(--x) * 0.5);
  border-radius: calc(var(--x) * 0.5);
  display: flex !important;
  padding: calc(var(--x) * 0.5) calc(var(--x) * 1);

  .userName, .quesDesc, .btn-toolbar {
    width: 33.3%;
  }

  .btn-toolbar {
    text-align: right;
    display: block;

    button {
      margin-bottom: 0px;
    }

    .is-destructive {
      padding: 8px;
      text-align: center;

      .icon {
        margin: 0px !important
      }
    }
  }

  &.is-error {
    border: 1.4px solid var(--fld-bdr-error);
  }

  &.is-warning {
    border: 1.4px solid var(--fld-bdr-warning);
  }

}

.show--questionnaire {
  display: block;
  -webkit-animation: slide-up 0.1s ease-out;
  -moz-animation: slide-up 0.3s ease-out;
}

.hide--questionnaire {
  display: none !important;
  -webkit-animation: slide-down 0.1s ease-out;
  -moz-animation: slide-down 0.3s ease-out;
}

@-webkit-keyframes slide-up {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

@-moz-keyframes slide-up {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0%;
  }
}

@-webkit-keyframes slide-down {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@-moz-keyframes slide-down {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@media only screen and (min-width: 1440px) {
  .table-scroll {
    max-width: 1050px;
  }
}

@media only screen and (min-width: 1620px) {
  .table-scroll {
    max-width: 1225px;
  }
  .probate-beneficiary-table {
    margin: auto;
    width: 1750px;
  }
}

@media only screen and (min-width: 1720px) {
  .probate-beneficiary-table {
    margin: auto;
    width: 1850px;
  }
  .table-scroll {
    max-width: 1350px;
  }
}

@media only screen and (min-width: 1920px) {
  .table-scroll {
    max-width: 1600px;
  }
}

@media only screen and (min-width: 2400px) {
  .table-scroll {
    max-width: 100%;
  }
  @mixin leftsidebar-common {
    height: 95vh;
  }
}

.probate-document-helper {
  @extend %inner;

  &.active {
    background-color: var(--bg-light);
  }
}

.dashboard-buttons {
  &--align_right {
    right: x(5);
    left: auto;
    position: absolute;
  }

  &--head {
    background-color: transparent;
    border-bottom: transparent;
    padding: 0;
  }
}

.db-card {

  background: var(--bg-card);
  color: var(--fg);

  //border: 1px solid var(--bdr);
  padding: calc(var(--x) * 1.5);

  box-sizing: border-box;

  margin-bottom: x(2);
  position: relative;

  &.is-loading {
    @extend %animation-loading;
  }
}

.h_line {
  border-top: 1px solid var(--bdr);
  padding: calc(var(--x) * 1.2) 0px;
  margin: auto;
}

.popover-body {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  white-space: pre-wrap;
}

.fnxtable_compact {
  @extend .fnxtable;
  @extend .fnxtable--flat;

  &--flat {
    margin-bottom: 0;
  }

  //Hidden last row border;
  tbody tr {
    &:last-child {
      border-bottom: 2px solid transparent;
    }
  }
}

//Toastify custom success
.toast-success-container {
  color: #000 !important;
  border-radius: 8px !important;
  background: #FFFFFF !important;
  border: 1px solid #34A853 !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.toast-success-container-after {
  overflow: hidden;
  position: relative;
}

.toast-success-container-after::after {
  top: 0;
  left: 0;
  content: '';
  width: 7px;
  height: 100%;
  position: absolute;
  display: inline-block;
  background-color: #34A853;
}

.footer_title {
  padding-left: calc(var(--x) * 3);
}
